import React from 'react';
import {FaSearch} from "react-icons/fa";
import PosOptionBox from "./PosOptionBox";

const OrderItemsSelector = (props) => {
    return (
        <>
            {props.selectedItems.length === 0 ? (
                <div className="row mt-3">
                    <div className="col-10">
                        <div className="search-container">
                            <FaSearch className="search-icon"/>
                            <input type="text" placeholder="Buscar..." onInput={props.searchProduct}/>
                        </div>
                    </div>
                </div>
            ) : null}
            <div className="row">
                <div className="col-12 mt-3" style={{overflowY: "auto", maxHeight: "calc(100vh - 120px)"}}>
                    {props.selectedItems.length === 0 ? (
                        <>
                            <div className={`row ${props.selectedCategories.length > 0 ? "d-none d-md-block" : ""}`}>
                                <div className="col-12">
                                    <div className="dialog-content-flex">
                                        {props.categories.map((category, index) => (
                                            <PosOptionBox
                                                clickAction={() => props.handleSelectCategory(category.id)}
                                                key={index}
                                                text={category.name}
                                                selected={props.selectedCategories.includes(category.id)}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className={props.selectedCategories.length > 0 ? "d-none d-md-block" : ""} style={{borderBottom: "1px solid #dee2e6", marginTop: "5px"}}/>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <div className="dialog-content-flex">
                                        {props.itemsAux.sort((a, b) => a.title.localeCompare(b.title)).map((item, index) => (
                                            <PosOptionBox
                                                key={index}
                                                text={item.title}
                                                selected={props.selectedItems.includes(item)}
                                                clickAction={() => props.setSelectedItems([item])}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div style={{borderBottom: "1px solid #dee2e6", marginTop: "5px"}}/>
                        </>
                    ) : null}
                    {props.selectedItems.length > 0 ? (
                        <div className="row mt-3">
                            <div className="col-12">
                                <div className="row justify-content-between align-items-center">
                                    <div className="col-auto">
                                        <div className="counter-container">
                                            <button
                                                style={{
                                                    backgroundColor: "transparent",
                                                    border: "none"
                                                }}
                                                onClick={() => props.setPosQuantity(props.posQuantity - 1)}
                                                disabled={props.posQuantity <= 1}
                                            >
                                                <div className="counter">
                                                    <span className="counter">-</span>
                                                </div>
                                            </button>
                                            <div className="counter">
                                                <span className="counter">{props.posQuantity}</span>
                                            </div>
                                            <button
                                                style={{
                                                    backgroundColor: "transparent",
                                                    border: "none"
                                                }}
                                                onClick={() => props.setPosQuantity(props.posQuantity + 1)}
                                                disabled={props.posQuantity >= 10}
                                            >
                                                <div className="counter">
                                                    <span className="counter">+</span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col text-center">
                                        <h5>{props.selectedItems[0].title}</h5>
                                    </div>
                                    <div className="col-auto">
                                        <div className="counter-container">
                                            {!isNaN(props.summarySelectedItem) && props.summarySelectedItem !== null ? (
                                                <button
                                                    style={{
                                                        backgroundColor: "transparent",
                                                        border: "none",
                                                        margin: 0,
                                                        padding: 0
                                                    }}
                                                    onClick={() => props.removeItem(props.summarySelectedItem)}
                                                >
                                                    <div className="cancel-button-pos-order">
                                                        Quitar
                                                    </div>
                                                </button>
                                            ) : (
                                                <button
                                                    style={{
                                                        backgroundColor: "transparent",
                                                        border: "none",
                                                        margin: 0,
                                                        padding: 0
                                                    }}
                                                    onClick={() => props.setSelectedItems([])}
                                                >
                                                    <div className="cancel-button-pos-order">
                                                        Cancelar
                                                    </div>
                                                </button>
                                            )}
                                            {!isNaN(props.summarySelectedItem) && props.summarySelectedItem !== null ? (
                                                <button
                                                    style={{
                                                        backgroundColor: "transparent",
                                                        border: "none",
                                                        color: "white",
                                                        fontWeight: "bold",
                                                        margin: 0,
                                                        padding: 0
                                                    }}
                                                    onClick={() => {
                                                        // Assuming you have logic to determine the index and the updated item details
                                                        const indexToUpdate = props.summarySelectedItem; // This should be the index of the item in the original items array
                                                        const updatedItemDetails = {
                                                            // Construct the updated item details here
                                                            // For example:
                                                            item: props.selectedItems[0],
                                                            variants: props.selectedVariants,
                                                            quantity: props.posQuantity,
                                                            // Any other details you need to update
                                                        };

                                                        // Now invoke the updateItems function passed down through props
                                                        props.updateItem(indexToUpdate, updatedItemDetails);
                                                        props.setSummarySelectedItem(null);
                                                    }}
                                                    disabled={!(props.selectedVariants.length === props.variantsAux.length)}
                                                >
                                                    <div
                                                        className={`cancel-button-pos-order ms-2 ${props.selectedVariants.length === props.variantsAux.length ? "done-button-pos-order-active" : "done-button-pos-order-disabled"}`}
                                                    >
                                                        Actualizar
                                                    </div>
                                                </button>
                                            ) : (
                                                <button
                                                    style={{
                                                        backgroundColor: "transparent",
                                                        border: "none",
                                                        color: "white",
                                                        fontWeight: "bold",
                                                        margin: 0,
                                                        padding: 0
                                                    }}
                                                    onClick={props.addItem}
                                                    disabled={!(props.selectedVariants.length === props.variantsAux.length)}
                                                >
                                                    <div
                                                        className={`cancel-button-pos-order ms-2 ${props.selectedVariants.length === props.variantsAux.length ? "done-button-pos-order-active" : "done-button-pos-order-disabled"}`}
                                                    >
                                                        Agregar +
                                                    </div>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div style={{borderBottom: "1px solid #dee2e6", marginTop: "8px"}}/>

                                {props.variantsAux.map((category, index) => (
                                    <>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <p>{category.title}</p>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <div className="dialog-content-flex">
                                                    {category?.variants?.map((variant, index) => (
                                                        <PosOptionBox
                                                            key={index}
                                                            text={variant.title}
                                                            clickAction={() => props.handleSelectVariant(variant, category)}
                                                            selected={props.selectedVariants.some(selectedVariant => selectedVariant.id === variant.id)}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default OrderItemsSelector;