import React from 'react';
import {money_format} from "./functions/numberFormat";
import {useNavigate} from "react-router-dom";

const OrderContainer = (props) => {

    const navigate = useNavigate();

    return (
        <div className={`pos-order-container ${props.selected ? "pos-order-container-selected" : ""}`} onClick={() => props.setOrderID(props.hash)}>
            <div className="row align-items-center justify-content-center">
                <div className="col-md-2 text-center d-none d-md-block">
                    <p className="m-0">
                        <b>#{props.id}</b>
                    </p>
                </div>
                <div className="col-md-10 col-12" style={{borderLeft: '1px solid #ccc'}}>
                    <div className="row">
                        <div className="col-md-8 text-start pe-0">
                            <p className="mb-1" style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                fontWeight: 'bold',
                            }}>
                                {props.title}
                            </p>
                            <p className="m-0">
                                {props.date_started}
                            </p>
                        </div>
                        <div className="col-md-4 text-md-end text-start ps-md-1">
                            <p className="m-0">
                                {money_format(props.price)}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderContainer;