import React from 'react';
import { Tooltip } from "@mui/material";
import { FaCheck, FaPrint } from "react-icons/fa";
import Swal from 'sweetalert2';

const PanelBarraOrderComponent = (props) => {

    const handleDeliverOrder = (orderHash) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "¿Quieres marcar este pedido como entregado?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, entregado!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                props.deliverOrder(orderHash);
                Swal.fire(
                    'Entregado!',
                    'El pedido ha sido marcado como entregado.',
                    'success'
                );
            }
        });
    };

    return (
        <div className="row mt-3">
            <div className="col-md-11 offset-1 panel-barra-order-container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-auto">
                        <p className="mb-0 panel-barra-order-title">Pedido: {props.order?.id}</p>
                    </div>
                    <div className="col-auto text-center">
                        <Tooltip title="Marcar como entregado" placement="top">
                            <button
                                className="btn deliver-button"
                                disabled={true}
                                onClick={() => handleDeliverOrder(props.order?.order_hash)}
                            >
                                <FaCheck />
                            </button>
                        </Tooltip>
                        <Tooltip title="Imprimir" placement="top">
                            <button
                                className="btn print-button ms-2"
                                onClick={() => props.printOrder(props.order?.order_hash)}
                            >
                                <FaPrint />
                            </button>
                        </Tooltip>
                    </div>
                    {props.order?.table_number && (
                        <div className="col text-end">
                            <p className="mb-0 panel-barra-order-table">{props.order?.table_name}</p>
                        </div>
                    )}
                </div>
                <div className="row mt-2 align-items-center">
                    <div className="col-auto">
                        <p className="mb-0 panel-barra-order-items-title">Items:</p>
                    </div>
                </div>
                {props.order?.products.map((product) => (
                    <div className="row mt-2" key={product.id}>
                        <div className="col-12">
                            <p className="mb-0 panel-barra-order-product">
                                <span className="font-weight-bold">{product.quantity}x ({product?.category_name})</span> {product?.title}
                            </p>
                            {product?.variants?.length > 0 && product?.variants.map((variant) => (
                                <p className="m-0 panel-barra-order-variant" key={variant.id}>
                                    <small>{variant?.category_name}: {variant?.name}</small>
                                </p>
                            ))}
                        </div>
                    </div>
                ))}
                {props.order?.observations && (
                    <div className="row mt-2 align-items-center">
                        <div className="col-auto">
                            <p className="mb-1 panel-barra-order-observations-title">Observaciones:</p>
                            <p className="panel-barra-order-observations">{props.order?.observations}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PanelBarraOrderComponent;
