import React from 'react';

const SuccessIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100"  // Adjust the size as needed
        height="100" // Adjust the size as needed
        viewBox="0 0 24 24"
        fill="none"
    >
        <circle cx="12" cy="12" r="10" stroke="#28a745" strokeWidth="2" fill="#28a745" />
        <path d="M9 12l2 2 4-4" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default SuccessIcon;
