import React from 'react';
import '../styles/Home.css';
import {useNavigate} from "react-router-dom";
import {click} from "@testing-library/user-event/dist/click";

const PosOptionBox = ({ icon, text, to, size = 100, clickAction, selected }) => {

    const navigate = useNavigate();

    if (!clickAction) {
        clickAction = () => navigate(to);
    }

    const iconSize = 0.4*size;
    const textSize = 0.12*size;

    return (
        <button onClick={clickAction} style={{border: "none", backgroundColor: "transparent", marginBottom: "3px", marginTop: 0}}>
            <div className={`pos-option-box ${selected ? "pos-option-box-selected" : ""}`} style={{height: size, width: size}}>
                {icon ? (
                    <div className="pos-icon" style={{fontSize: iconSize}}>
                        {icon}
                    </div>
                ) : null}
                <p className={`${icon ? "pos-text" : "pos-text-item"}`} style={{fontSize: textSize}}>{text}</p>
            </div>
        </button>
    );
};

export default PosOptionBox;
