import React, {useEffect, useState} from 'react';
import '../styles/PasswordInput.css';
import { FiDelete } from "react-icons/fi";

const PasswordInput = (props) => {
    const [passcode, setPasscode] = useState(props.password || '');
    const maxPasscodeLength = 6; // Assuming a passcode length of 6

    const keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'C', '0', <FiDelete />];

    const updatePasscode = (key) => {
        if (key === 'C') {
            setPasscode('');
        } else if (key === '⨉') {
            setPasscode(passcode.slice(0, -1));
        } else if (passcode.length < maxPasscodeLength) {
            setPasscode(passcode + key);
        }
    };

    const keysWithFunctionAndClass = keys.map(key => ({
        key: key,
        onPress: () => updatePasscode(typeof key === 'string' ? key : '⨉'),
        className: `key-password-button-container key-class-${typeof key === 'string' ? key.replace(/\W/g, '') : 'icon'}`
    }));

    useEffect(() => {
        props.setPassword(passcode);
    }, [passcode]);

    useEffect(() => {
        setPasscode(props.password);
    }, [props.password]);

    return (
        <div className="password-container">
            <div className="row">
                <div className="col-12">
                    <div className="passcode-display">
                        {passcode === "" ? (
                            <div>
                                <p className="mb-0" style={{fontSize: "16px", color: "black"}}>{props.placeholder}</p>
                            </div>
                        ) : Array.from({ length: maxPasscodeLength }).map((_, index) => (
                            <div key={index} className="passcode-dot">
                                {index < passcode.length ? '•' : ''}
                            </div>
                        ))}
                    </div>
                    <hr style={{ margin: '0.7rem 0' }} />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="key-password-buttons-grid">
                        {keysWithFunctionAndClass.map((key, index) => (
                            <button key={index} className={key.className} onClick={key.onPress}>
                                <div className="key-password-button">
                                    {key.key}
                                </div>
                            </button>
                        ))}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <button
                        className={`password-submit-button ${!props.password ? "disabled" : ""}`}
                        onClick={props.submitFunction}
                        disabled={!props.password || props.isLoading}
                    >
                        CONFIRMAR
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PasswordInput;