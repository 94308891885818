// src/database.js
import { openDB } from 'idb';

const setupDatabase = async () => {
    const db = await openDB('pos-system', 1, {
        upgrade(db) {
            if (!db.objectStoreNames.contains('transactions')) {
                db.createObjectStore('transactions', { autoIncrement: true });
            }
        },
    });
    return db;
};

export default setupDatabase;