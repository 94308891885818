import React, {useEffect, useState} from 'react';
import {FaLongArrowAltLeft} from "react-icons/fa";
import {formatAmountDisplay, money_format} from "./functions/numberFormat";
import {BsCashStack} from "react-icons/bs";
import {FaRegCreditCard} from "react-icons/fa6";
import {IoPersonCircleOutline, IoQrCodeOutline} from "react-icons/io5";
import {CircularProgress, Skeleton, TextField} from "@mui/material";
import SuccessIcon from "./SuccessIcon";
import {TiPrinter} from "react-icons/ti";
import PaymentInput from "./PaymentInput";
import {useLocation, useNavigate} from "react-router-dom";
import {MdOutlineEdit, MdOutlineModeEditOutline} from "react-icons/md";
import {RxCrossCircled} from "react-icons/rx";
import Swal from "sweetalert2";

const OrderSummary = (props) => {

    const navigate = useNavigate();

    // State to hold the dynamic height of the container
    const [containerHeight, setContainerHeight] = useState('40vh'); // Initialize with a default value
    const [orderTotalsHeight, setOrderTotalsHeight] = useState('140px');

    const [functionIsProcessing, setFunctionIsProcessing] = useState(false);

    const handleOrderClick = async () => {
        setFunctionIsProcessing(true);
        try {
            if (props.mustUpdateOrder) {
                await props.updateOrder();
            } else {
                await props.submitOrder();
            }
        } catch (error) {
            console.error("Error processing order:", error);
        } finally {
            setFunctionIsProcessing(false);
        }
    };

    const isOrderOpen = () => {
        if (!props.values || props.values.order_status === "placed" || props.values.order_status === null || !(props.values.hasOwnProperty('order_status'))) {
            return true;
        } else {
            return false;
        }
    }

    const handleVoidPaymentConfirmation = () => {
        Swal.fire({
            title: '¿Estás seguro que deseas anular este pago?',
            text: "No podrás revertir esta acción",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Anular',
        }).then((result) => {
            if (result.isConfirmed) {
                requestAuthorizationCode();
            }
        });
    };

    const requestAuthorizationCode = () => {
        Swal.fire({
            title: 'Código de autorización',
            input: 'password',
            inputLabel: 'Por favor, introduce el código de autorización del supervisor',
            inputPlaceholder: 'Código de autorización',
            showCancelButton: true,
            confirmButtonText: 'Enviar',
            showLoaderOnConfirm: true,
            preConfirm: (authorizationCode) => {
                return new Promise((resolve, reject) => {
                    resolve(authorizationCode);
                }).catch(error => {
                    Swal.showValidationMessage('Código de autorización inválido');
                    // Permitir que el usuario intente nuevamente
                    return false;
                });
            }
        }).then((authorizationResult) => {
            if (authorizationResult.isConfirmed) {
                voidPayment(authorizationResult.value);
            } else if (authorizationResult.dismiss === Swal.DismissReason.cancel) {
                Swal.fire({
                    title: 'Acción cancelada',
                    text: 'La anulación del pago ha sido cancelada.',
                    icon: 'info',
                    showConfirmButton: true,
                });
            }
        });
    };

    const voidPayment = (authorizationCode) => {
        Swal.fire({
            title: 'Anulando pago...',
            html: 'Por favor, espera un momento.',
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading();
            },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false
        });

        if (props.summarySelectedPayments !== null) {
            props.voidPayment(props.summarySelectedPayments, authorizationCode).then(response => {
                Swal.close();

                if (response.status === "approved") {
                    Swal.fire({
                        title: 'Pago anulado con éxito',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                    });
                    props.setSummarySelectedPayments(null);
                    props.fetchOrder(props.orderID);
                } else {
                    Swal.fire({
                        title: 'Error al anular el pago',
                        text: response.message,
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
            }).catch(error => {
                Swal.close();
                Swal.fire({
                    title: 'Error al anular el pago',
                    text: error.message || 'Ha ocurrido un error',
                    icon: 'error',
                    showConfirmButton: true
                });
            });
        }
    };



    useEffect(() => {
        const adjustContainerHeight = () => {
            // Calculate the dynamic height based on the current viewport height.
            // This calculation can be adjusted to account for any other elements or padding you have on the page.
            // For example, if the total height of other elements is known to be 35vh, you might want to set this container to (100vh - 35vh)
            if (props.orderRevision) {
                const newHeight = `calc(100vh - 60px - 270px)`; // Adjust 100px to your actual combined height of other elements
                setContainerHeight(newHeight);
                setOrderTotalsHeight("180px");
            } else {
                const newHeight = `calc(100vh - 60px - 250px)`; // Adjust 100px to your actual combined height of other elements
                setContainerHeight(newHeight);
                setOrderTotalsHeight("160px");
            }
        };

        // Adjust height on component mount and window resize
        window.addEventListener('resize', adjustContainerHeight);
        adjustContainerHeight();

        // Cleanup
        return () => window.removeEventListener('resize', adjustContainerHeight);
    }, []);

    return (
        <div className="pos-order-summary">
            <div className="row p-3">
                <div className="col-12">
                    <div className="row pos-order-summary-title">
                        <div className="col-12 d-flex align-items-center">
                            {props.isLoading ? (
                                <>
                                    <Skeleton variant="text" width={140} height={30} style={{borderRadius: "10px"}}/>
                                </>
                            ) : (
                                <>
                                    <button
                                        style={{backgroundColor: "transparent", border: "none", margin: 0, padding: 0}}
                                        onClick={() => {
                                            if (props.mode === "orders" && props.mobileSummaryOpen) {
                                                props.setOrderID(null);
                                            }
                                            else if (props.mobileSummaryOpen && !props.waitingForPayment) {
                                                props.setMobileSummaryOpen(false);
                                            }
                                            else {
                                                if (props.mode === "order-interface" && props.waitingForPayment) {
                                                    props.cancelPaymentIntent();
                                                }
                                                else if (props.mode === "orders") {
                                                    navigate("/cash-register-interface");
                                                }
                                                else {
                                                    navigate("/orders");
                                                }
                                            }
                                        }}>
                                        <FaLongArrowAltLeft className="me-2"/>
                                    </button>
                                    {props.values.id ? (
                                        <span>Orden #{props.values.id}</span>
                                    ) : (
                                        <span>Nueva orden</span>
                                    )}
                                    <div className={`pos-table-selector-container ms-3 ${props.displayPaymentMethod || props.waitingForPayment || props.mode != "order-interface" ? "disabled" : "disabled"}`} onClick={() => {
                                        if (!props.displayPaymentMethod && !props.waitingForPayment && props.mode === "order-interface") {
                                            //props.setClientListOpen(true);
                                        }
                                    }}>
                                        <IoPersonCircleOutline/>
                                        <p className="mb-0 ms-2">{props.client ? props.client.first_name + " " + props.client.last_name : "Cliente"}</p>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    {
                        //<div style={{borderBottom: "1px solid #dee2e6"}}/>
                    }
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="pos-order-items-container" style={{ height: containerHeight, overflowY: 'scroll' }}>
                        {props.isLoading ? (
                            <>
                                {Array.from(Array(12).keys()).map((i) => (
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: 10,
                                        padding: "5px 10px"
                                    }}>
                                        <Skeleton variant="rectangular" width={40} height={40}
                                                  style={{borderRadius: "4px"}}/>
                                        <div style={{marginLeft: 10, flex: 1}}>
                                            <Skeleton variant="text" style={{marginBottom: 6}}/>
                                            <Skeleton variant="text" width="60%"/>
                                        </div>
                                    </div>
                                ))}
                            </>
                        ) : !props.waitingForPayment && props.paymentSuccess ? (
                            <div className="row mt-1 mb-2 pos-order-item align-items-center text-center">
                                <div className="col-12">
                                    <div className="row mt-5 align-items-center justify-content-center">
                                        <div className="col-12">
                                            <SuccessIcon/>
                                        </div>
                                    </div>
                                    <div className="row mt-3 text-center align-items-center">
                                        <div className="col-12">
                                            <p className="pos-order-category">Pago recibido con éxito</p>
                                            {!isNaN(props.change) ? (
                                                <p style={{fontSize: "14pt"}} className="pos-order-category mt-3">Cambio: {formatAmountDisplay(props.change * 100)}</p>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : props.waitingForPayment ? (
                            <div className="row mt-1 mb-2 pos-order-item align-items-center">
                                <div className="col-12">
                                    <div className="row mt-3 text-center align-items-center">
                                        <div className="col-12">
                                            <p className="pos-order-category">Esperando el pago...</p>
                                        </div>
                                    </div>
                                    <div className="row mt-3 align-items-center justify-content-center">
                                        <div className="col-12">
                                            <div style={{
                                                position: "relative",
                                                width: "100%",
                                                height: "300px"
                                            }}>
                                                <div style={{
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    transform: `translate(-50%, -50%)`
                                                }}>
                                                    <CircularProgress style={{color: "rgba(25, 118, 210, 0.8)"}}
                                                                      size={100}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (() => {
                            // Group items by category name and include their original index
                            const groupedItems = props.values.items.reduce((acc, item, originalIndex) => {
                                const categoryName = item?.item?.categoryName || 'Other';
                                acc[categoryName] = acc[categoryName] || [];
                                acc[categoryName].push({ ...item, originalIndex }); // Include original index
                                return acc;
                            }, {});

                            // Function to render each item, modified to use the original index for setSummarySelectedItem
                            const renderItem = (item, index) => {
                                // Use the originalIndex stored in item to reference the correct item in props.values.items
                                return (
                                    <div className={`row mt-1 mb-2 ${!isOrderOpen() ? "pos-order-item-disabled" : ""} ${props.mode === "order-interface" && !props.displayPaymentMethod ? "pos-order-item" : ""} align-items-center ${props.summarySelectedItem === item.originalIndex ? "selected" : ""}`} key={index} onClick={() => {
                                        if (props.mode === "order-interface" && !props.displayPaymentMethod && isOrderOpen()) {
                                            props.setSummarySelectedItem(item.originalIndex)
                                        }
                                    }}>
                                        <div className="col-2 d-flex justify-content-end align-items-top">
                                            <div className="pos-order-item-quantity-container">
                                                <span>{item.quantity}</span>
                                            </div>
                                        </div>
                                        <div className="col-4 ps-0">
                                            <p className="pos-order-item-title">{item?.item?.title}</p>
                                            {item?.variants?.map((variant, variantIndex) => (
                                                <p className="pos-order-item-variant" key={variantIndex}>{variant.categoryName}: {variant.title}</p>
                                            ))}
                                        </div>
                                        <div className="col-3">
                                            <p className="pos-order-item-price">{money_format(item?.item?.price)}</p>
                                        </div>
                                        <div className="col-3">
                                            <p className="pos-order-item-total-price">{money_format(item?.item?.price * item.quantity)}</p>
                                        </div>
                                    </div>
                                );
                            };

                            // Render the grouped items
                            const renderGroupedItems = Object.entries(groupedItems).map(([categoryName, items], index) => (
                                <div key={index}>
                                    <div className="row mb-2 pos-order-category-container">
                                        <div className="col-12">{categoryName}</div>
                                    </div>
                                    {items.map(renderItem)}
                                </div>
                            ));

                            const renderPayment = (payment, index) => {
                                // Use the originalIndex stored in payment to reference the correct payment in props.values.payments
                                return (
                                    <div className={`row mt-1 mb-2 pos-order-item align-items-center ${props.summarySelectedPayments === payment.mop_id ? "selected" : ""}`} key={index} onClick={() => {
                                        if (props.mode === "order-interface") {
                                            props.setSummarySelectedPayments(payment.mop_id);
                                        }
                                    }}>
                                        <div className="col-2 d-flex justify-content-end align-items-top">
                                            <div className="pos-order-item-quantity-container">
                                                <span style={{fontSize: "10pt"}}>{`${payment?.payment_method === "account_money" ? "MP" : payment?.payment_method === "checking_account" ? "CC" : payment?.payment_method === "security_staff" ? "SEG": payment?.payment_method === "bank_transfer" ? "T. BANC" : payment?.payment_method}`}</span>
                                            </div>
                                        </div>
                                        <div className="col-7 ps-0">
                                            <p className="pos-order-item-title">{`${payment?.id_name ? `${payment?.id_name}, ${payment?.tarjeta_lastfour}` : "Sin Identificar"}`}</p>
                                        </div>
                                        <div className="col-3">
                                            <p className="pos-order-item-total-price">{money_format(payment?.transaction_amount)}</p>
                                        </div>
                                    </div>
                                );
                            };

                            // Render the grouped payments
                            const renderGroupedPayments = props?.values?.transactions?.map((payment, index) => (
                                <div key={index}>
                                    {renderPayment(payment, index)}
                                </div>
                            ));

                            return (
                                <>
                                    {renderGroupedItems}
                                    {((props.displayPaymentMethod && !props.waitingForPayment) || (props.mobileSummaryOpen)) && (props?.values?.transactions?.length ?? 0) > 0 ? (
                                        <>
                                            <div className="row mt-5 mb-2 pos-order-category-container selected">
                                                <div className="col-12">Pagos</div>
                                            </div>
                                            {renderGroupedPayments}
                                        </>
                                    ) : null}
                                </>
                            );
                        })()}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="pos-order-summary-total" style={{overflowY: 'scroll', height: orderTotalsHeight}}>
                        {props.isLoading ? (
                                <>
                                    <div className="row justify-content-between">
                                        <div className="col-6">
                                            <Skeleton variant="text" width={100} height={30} style={{borderRadius: "10px"}}/>
                                        </div>
                                        <div className="col-auto">
                                            <Skeleton variant="text" width={100} height={30} style={{borderRadius: "10px"}}/>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-12">
                                            <div className="row justify-content-between">
                                                <div className="col-6">
                                                    <Skeleton variant="text" width={100} height={80} style={{borderRadius: "10px"}}/>
                                                </div>
                                                <div className="col-6 text-end d-flex justify-content-end">
                                                    <Skeleton variant="text" width={150} height={80} style={{borderRadius: "10px"}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                            <>
                                {props.orderRevision ? (
                                    <>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="row justify-content-between align-items-center">
                                                    <div className="col-6 d-flex justify-content-between align-items-center">
                                                        <button
                                                            style={{backgroundColor: "white", color: "#1976d2", fontFamily: "Lato, sans-serif", fontWeight: 900, borderRadius: "0.5rem", border: "1px solid #1976d2", padding: "0.5rem 0.7rem", width: "100%", fontSize: "1.1rem"}}
                                                            onClick={
                                                                () => {
                                                                    navigate(`/orders/${props.orderID}`)
                                                                }
                                                            }
                                                        >
                                                            <MdOutlineModeEditOutline size={25} className="me-1" /> Actualizar
                                                        </button>
                                                    </div>
                                                    <div
                                                        className="col-6 d-flex justify-content-between align-items-center">
                                                        <button
                                                            style={{
                                                                backgroundColor: "white",
                                                                color: "#1976d2",
                                                                fontFamily: "Lato, sans-serif",
                                                                fontWeight: 900,
                                                                borderRadius: "0.5rem",
                                                                border: "1px solid #1976d2",
                                                                padding: "0.5rem 0.7rem",
                                                                width: "100%"
                                                            }}
                                                            className={`pos-order-summary-discount-button`}
                                                            onClick={() => {
                                                                props.printOrder(props.orderID, props.setIsLoading);
                                                                props.printOrderInSession(props.orderID);
                                                            }}
                                                            disabled={props.values.order_status !== "processing"}
                                                        >
                                                            <TiPrinter size={25} className="me-1"/> Imprimir recibo
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-1 justify-content-between">
                                            <div className="col-6">
                                                <p style={{fontSize: "12pt"}}>Subtotal:</p>
                                            </div>
                                            <div className="col-auto">
                                                <p style={{fontSize: "12pt"}}>{formatAmountDisplay(props.values.items.reduce((acc, item) => acc + (item.item.price * item.quantity), 0) * 100)}</p>
                                            </div>
                                        </div>
                                        <div className="row justify-content-between">
                                            <div className="col-6">
                                            <p style={{fontSize: "10pt"}}>Restante:</p>
                                            </div>
                                            <div className="col-auto">
                                                <p style={{fontSize: "10pt"}}>{formatAmountDisplay(!isNaN(props.values.remaining_amount) ? props.values.remaining_amount * 100 : props.values.items.reduce((acc, item) => acc + (item.item.price * item.quantity), 0) * 100)}</p>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <div className="row justify-content-between">
                                                    <div className="col-12">
                                                        <button
                                                            style={{
                                                                backgroundColor: "#1976d2",
                                                                color: "white",
                                                                fontFamily: "Lato, sans-serif",
                                                                fontWeight: 900,
                                                                borderRadius: "0.5rem",
                                                                border: "none",
                                                                padding: "0.5rem 0.7rem",
                                                                width: "100%",
                                                                fontSize: "1.1rem"
                                                            }}
                                                            onClick={
                                                                () => navigate(`/orders/${props.orderID}`, { state: { goToPayment: true } })
                                                            }
                                                        >
                                                            Pagar {formatAmountDisplay(!isNaN(props.values.remaining_amount) ? props.values.remaining_amount * 100 : props.values.items.reduce((acc, item) => acc + (item.item.price * item.quantity), 0) * 100)}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : props.displayPaymentMethod ? (
                                    <>
                                        <div className="row justify-content-between">
                                            <div className="col-6">
                                                <p>Subtotal:</p>
                                            </div>
                                            <div className="col-auto">
                                                <p>{formatAmountDisplay(props.values.items.reduce((acc, item) => acc + (item.item.price * item.quantity), 0) * 100)}</p>
                                            </div>
                                        </div>
                                        <div className="row justify-content-between">
                                            <div className="col-6">
                                                <p style={{fontSize: "11pt"}}>Restante:</p>
                                            </div>
                                            <div className="col-auto">
                                                <p style={{fontSize: "11pt"}}>{formatAmountDisplay(!isNaN(props.values.remaining_amount) ? props.values.remaining_amount * 100 : props.values.items.reduce((acc, item) => acc + (item.item.price * item.quantity), 0) * 100)}</p>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <div className="row justify-content-center">
                                                    {props.waitingForPayment ? (
                                                            <>
                                                                <div className="col-12">
                                                                    <button
                                                                        className="pos-order-summary-discount-button"
                                                                        onClick={
                                                                            () => {
                                                                                props.cancelPaymentIntent()
                                                                            }
                                                                        }
                                                                    >
                                                                        Volver
                                                                    </button>
                                                                </div>
                                                            </>
                                                        )
                                                        : !isNaN(props.summarySelectedPayments) && props.summarySelectedPayments !== null && !props.paymentSuccess ? (
                                                            <>
                                                                <div className="col-6">
                                                                    {props.displayPaymentMethod ? (
                                                                        <button
                                                                            className="pos-order-summary-void-button"
                                                                            onClick={
                                                                                () => {
                                                                                    handleVoidPaymentConfirmation();
                                                                                }
                                                                            }
                                                                        >
                                                                            <RxCrossCircled className="me-1" /> <span>Anular pago</span>
                                                                        </button>

                                                                    ) : null}
                                                                </div>
                                                                <div className="col-6">
                                                                    {props.displayPaymentMethod ? (
                                                                        <button
                                                                            className="pos-order-summary-discount-button"
                                                                            onClick={
                                                                                () => {
                                                                                    props.setDisplayPaymentMethod(false)
                                                                                    if (props.orderID) {
                                                                                        props.cancelPaymentIntent()
                                                                                    }
                                                                                }
                                                                            }
                                                                        >
                                                                            Hecho
                                                                        </button>

                                                                    ) : null}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <div className="col-12">
                                                                {props.displayPaymentMethod ? (
                                                                    <button
                                                                        className="pos-order-summary-discount-button"
                                                                        onClick={
                                                                            () => {
                                                                                props.setDisplayPaymentMethod(false)
                                                                                if (props.orderID) {
                                                                                    props.cancelPaymentIntent()
                                                                                }
                                                                            }
                                                                        }
                                                                    >
                                                                        Hecho
                                                                    </button>

                                                                ) : null}
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="row justify-content-between">
                                            <div className="col-6">
                                                <p>Subtotal:</p>
                                            </div>
                                            <div className="col-auto">
                                                <p>{formatAmountDisplay(props.values.items.reduce((acc, item) => acc + (item.item.price * item.quantity), 0) * 100)}</p>
                                            </div>
                                        </div>
                                        <div className="row justify-content-between">
                                            <div className="col-6">
                                                <p style={{fontSize: "11pt"}}>Restante:</p>
                                            </div>
                                            <div className="col-auto">
                                                <p style={{fontSize: "11pt"}}>{formatAmountDisplay(!isNaN(props.values.remaining_amount) ? props.values.remaining_amount * 100 : props.values.items.reduce((acc, item) => acc + (item.item.price * item.quantity), 0) * 100)}</p>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <div className="row justify-content-between">
                                                    <div className="col-6">
                                                        {props.displayPaymentMethod ? (
                                                                <button
                                                                    className="pos-order-summary-discount-button"
                                                                    onClick={
                                                                        () => {
                                                                            props.setDisplayPaymentMethod(false)
                                                                            if (props.orderID) {
                                                                                props.cancelPaymentIntent()
                                                                            }
                                                                        }
                                                                    }
                                                                >
                                                                    Volver
                                                                </button>

                                                            ) : props.mobileSummaryOpen && props.summarySelectedPayments ? (
                                                                <button
                                                                    className="pos-order-summary-void-button"
                                                                    onClick={
                                                                        () => {
                                                                            handleVoidPaymentConfirmation();
                                                                        }
                                                                    }
                                                                    // disabled
                                                                >
                                                                    <RxCrossCircled className="me-1"/>
                                                                    <span>Anular pago</span>
                                                                </button>
                                                            ) :
                                                            (
                                                                <button
                                                                    className={`pos-order-summary-discount-button`}
                                                                    onClick={() => {
                                                                        props.printOrder(props.orderID, props.setIsLoading);
                                                                        props.printOrderInSession(props.orderID);
                                                                    }}
                                                                    style={{padding: "0.9rem 0.7rem"}}
                                                                    disabled={!props.orderID || props.values.order_status !== "processing" || props.mustUpdateOrder}
                                                                >
                                                                    <TiPrinter size={25} className="me-1"/> Imprimir
                                                                    orden
                                                                </button>
                                                            )}
                                                    </div>
                                                    <div className="col-6">
                                                        {props.displayPaymentMethod && !props.mustUpdateOrder ? (
                                                                <button
                                                                    className="pos-order-summary-total-button"
                                                                    onClick={props.submitPayment}
                                                                    disabled={props.values.items.length === 0}
                                                                >
                                                                    Pagar {formatAmountDisplay(props.paymentAmount * 100)}
                                                                </button>
                                                            ) :
                                                            (
                                                                <button
                                                                    className="pos-order-summary-total-button"
                                                                    onClick={handleOrderClick}
                                                                    disabled={props.values.items.length === 0 || functionIsProcessing}
                                                                >
                                                                    {
                                                                        !props.orderID ? "Crear orden"
                                                                            : props.mustUpdateOrder ? "Actualizar orden"
                                                                            : `Pagar ${formatAmountDisplay(props.paymentAmount * 100)}`
                                                                    }
                                                                </button>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderSummary;