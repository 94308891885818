import React from 'react';
import PosOptionBox from "./PosOptionBox";
import {MdAddShoppingCart, MdPointOfSale} from "react-icons/md";

const CashRegistersInterface = () => {
    return (
        <div>
            <div className="home-container">
                <div className="row justify-content-center">
                    <div className="col-lg-3 col-md-4 col-sm-12 justify-content-center d-flex">
                        <PosOptionBox
                            icon={<MdPointOfSale/>}
                            text={"Gestión de caja"}
                            to={`/cash-register`}
                            size={200}
                        />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 justify-content-center d-flex">
                        <PosOptionBox
                            icon={<MdAddShoppingCart />}
                            text={"Ordenes"}
                            to={`/orders/new`}
                            size={200}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CashRegistersInterface;