import React, { useEffect, useState, useRef } from 'react';
import { printOrder, triggerPrinting } from "./functions/printOrder";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

const PendingPrinting = () => {
    const [pendingPrinting, setPendingPrinting] = useState([]);
    const [printedUrls, setPrintedUrls] = useState([]);
    const [historicalUrls, setHistoricalUrls] = useState([]);
    const historicalUrlsRef = useRef([]);
    const [pos, setPos] = useState([]);
    const [selectedPos, setSelectedPos] = useState([]);
    const isPrintingRef = useRef(false);

    const fetchPos = async () => {
        const request = await fetch('https://api.flashpass.com.ar/menu-pos', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        });

        if (!request.ok) {
            throw new Error(`Error: ${request.status}`);
        }

        const result = await request.json();

        if (result?.status === 'success') {
            setPos(result.registers);
        }
    };

    const fetchPendingPrinting = async (posIds) => {
        try {
            const posIdsParam = posIds.join(','); // Join all posIds with a comma
            const request = await fetch(`https://api.flashpass.com.ar/pos-printing-service?pos=${posIdsParam}&version=v2`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'credentials': 'same-origin',
                },
            });

            if (!request.ok) {
                throw new Error(`Error: ${request.status}`);
            }

            const result = await request.json();

            // Filter out URLs that are already printed or in the historicalUrlsRef state and remove duplicates
            const now = Date.now();
            const newTasks = result.filter((task, index, self) =>
                !printedUrls.includes(task.url) &&
                !historicalUrlsRef.current.some(urlObj => urlObj.url === task.url && (now - urlObj.timestamp) < 60000) &&
                index === self.findIndex((t) => t.url === task.url)
            );

            console.log('Historical URLs: ', historicalUrlsRef.current, 'Printed URLs: ', printedUrls, 'New tasks: ', newTasks, 'isPrintingRef: ', isPrintingRef.current);

            // Update state with new tasks
            setPendingPrinting(prevPendingPrinting => [...prevPendingPrinting, ...newTasks]);

            if (!isPrintingRef.current && newTasks.length > 0) {
                isPrintingRef.current = true;
                triggerPrintingQueue([...newTasks]);
            }
        } catch (error) {
            console.error("Failed to fetch pending printing tasks:", error);
        }
    };

    const triggerPrintingQueue = (tasks) => {
        if (tasks.length === 0) {
            isPrintingRef.current = false;
            return;
        }

        const [currentTask, ...remainingTasks] = tasks;

        console.log(currentTask, ...remainingTasks);

        // Mark URL as printed
        setPrintedUrls(prevPrintedUrls => [...prevPrintedUrls, currentTask.url]);
        setHistoricalUrls(prevHistoricalUrls => {
            const newHistoricalUrls = [...prevHistoricalUrls, { url: currentTask.url, timestamp: Date.now() }];
            historicalUrlsRef.current = newHistoricalUrls; // Update the ref
            return newHistoricalUrls;
        });

        // Trigger printing
        triggerPrinting(currentTask.order_hash, currentTask.url);

        setTimeout(() => {
            // Remove URL from pending tasks
            setPendingPrinting(prevPendingPrinting => prevPendingPrinting.filter(task => task.url !== currentTask.url));

            // Continue with the remaining tasks
            triggerPrintingQueue(remainingTasks);
        }, 4000); // 4 seconds delay between each print
    };

    useEffect(() => {
        fetchPos();
    }, []);

    useEffect(() => {
        if (selectedPos.length > 0) {
            const fetchInterval = setInterval(() => {
                fetchPendingPrinting(selectedPos);
            }, 4000); // Fetch every 4 seconds

            return () => clearInterval(fetchInterval); // Cleanup interval on component unmount
        }
    }, [selectedPos]);

    useEffect(() => {
        // Clean up historical URLs older than 60 seconds
        const cleanupInterval = setInterval(() => {
            const now = Date.now();
            setHistoricalUrls(prevHistoricalUrls => {
                const newHistoricalUrls = prevHistoricalUrls.filter(urlObj => (now - urlObj.timestamp) < 60000);
                historicalUrlsRef.current = newHistoricalUrls; // Update the ref
                return newHistoricalUrls;
            });
        }, 10000); // Check every 10 seconds

        return () => clearInterval(cleanupInterval); // Cleanup interval on component unmount
    }, []);

    return (
        <div>
            <FormGroup>
                <div className="row">
                    {pos.map((register, index) => (
                        <div className="col-auto" key={index}>
                            <FormControlLabel
                                control={<Checkbox />}
                                label={register.pos_name}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setSelectedPos([...selectedPos, register.pos_id]);
                                    } else {
                                        setSelectedPos(selectedPos.filter(pos => pos !== register.pos_id));
                                    }
                                }}
                            />
                        </div>
                    ))}
                </div>
            </FormGroup>
            {pendingPrinting.length > 0 ? (
                <ul>
                    {pendingPrinting.map((task, index) => (
                        <li key={index}>{task.menu_order_id /* Adjust according to your data structure */}</li>
                    ))}
                </ul>
            ) : (
                <p>No pending printing tasks found.</p>
            )}
        </div>
    );
};

export default PendingPrinting;
