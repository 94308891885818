import React, {useEffect, useState} from 'react';
import {money_format} from "./functions/numberFormat";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {useNavigate} from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {FaLongArrowAltLeft} from "react-icons/fa";
import {triggerPrinting} from "./functions/printOrder";


const MySwal = withReactContent(Swal);


const CashRegister = (props) => {

    const navigate = useNavigate();

    const [transactions, setTransactions] = useState([]);
    const [session, setSession] = useState(JSON.parse(localStorage.getItem('session')));
    const [openingBalance, setOpeningBalance] = useState(0);
    const [currentBalance, setCurrentBalance] = useState(0);
    const [currentBalanceTotal, setCurrentBalanceTotal] = useState(0);
    const [values, setValues] = useState({
        amount: 0,
        observations: "",
        event_id: 1676,
        pos_id: 1,
        menu_id: 6,
    });

    const fetchPendingPrinting = async (posId, action, transaction) => {
        try {
            const request = await fetch(`https://api.flashpass.com.ar/pos-printing-service?pos=${posId}&version=v2&session=${session?.session_id}&action=${action}&transaction=${transaction}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'credentials': 'same-origin',
                },
            });

            if (!request.ok) {
                throw new Error(`Error: ${request.status}`);
            }

            const result = await request.json();

            // Trigger printOrder for each task
            result.forEach(task => {
                setTimeout(() => triggerPrinting(task.order_hash, task.url), 2000); // Adjust this if printOrder expects a specific property from task
            });
        } catch (error) {
            console.error("Failed to fetch pending printing tasks:", error);
        }
    }

    const requestAmount = async (callback) => {
        const { value: amount } = await MySwal.fire({
            title: 'Ingrese el monto',
            input: 'text', // Podrías usar 'number', pero eso impediría que el usuario ingrese decimales en algunos navegadores
            inputPlaceholder: 'Monto',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            inputValidator: (value) => {
                if (!value || isNaN(value) || parseFloat(value) <= 0) {
                    return 'Por favor ingrese un monto válido';
                }
            }
        });

        if (amount) {
            callback(parseFloat(amount));
        }
    };

    const requestCloseOutBalance = async (callback) => {
        const { value: formValues } = await MySwal.fire({
            title: 'Cerrar caja',
            html: `
            <p style="margin: 0; font-weight: bold">Monto esperado: ${money_format(currentBalance)}</p>
            <input id="swal-input-amount" class="swal2-input" placeholder="Monto al cierre" type="number" step="any">
            <input id="swal-input-observations" class="swal2-input" placeholder="Observaciones">`,
            focusConfirm: false,
            preConfirm: () => {
                const amount = document.getElementById('swal-input-amount').value;
                if (!amount) {
                    MySwal.showValidationMessage("Por favor, complete todos los campos.");
                    return false;
                }
                if (isNaN(amount) || parseFloat(amount) < 0) {
                    MySwal.showValidationMessage("Ingrese un monto válido.");
                    return false;
                }
                return {
                    amount: document.getElementById('swal-input-amount').value,
                    observations: document.getElementById('swal-input-observations').value
                };
            },
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            inputValidator: (value) => {
                if (!value.amount || isNaN(value.amount) || parseFloat(value.amount) <= 0) {
                    return 'Por favor ingrese un monto válido';
                }
            }
        });

        if (formValues) {
            callback(parseFloat(formValues.amount), formValues.observations);
        }
    };

    const fetchData = async () => {
        // Retrieve client's timezone identifier
        const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Retrieve client's time difference in minutes and its direction
        const moment = require('moment-timezone');
        const gmtOffset = moment.tz(timeZone).utcOffset();

        const response = await fetch(`https://api.flashpass.com.ar/menu-pos-sessions?session_id=${session.session_id}&pos_id=${session.pos_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        });

        const result = await response?.json();

        if (result.status === "success") {
            const transactionsAux = result?.transactions?.map(transaction => {
                const date = new Date(transaction.created_at);
                date.setMinutes(date.getMinutes() + gmtOffset);
                transaction.created_at = date.toLocaleString();
                return transaction;
            });
            setTransactions(transactionsAux);
            setSession(result?.session);

            // Calcular saldo de apertura y saldo actual
            let opening = result?.session?.initial_amount;
            let current = 0;
            result?.transactions.filter(transaction => transaction.payment_method === "Efectivo").forEach(transaction => {
                const amount = parseFloat(transaction.amount);
                if (transaction?.transaction_type === "adjustment") {
                    current = amount; // Asumir el primer 'adjustment' como saldo de apertura
                } else {
                    current += amount; // Sumar todas las transacciones para el saldo actual
                }
            });

            let currentTotal = 0;
            result?.transactions.forEach(transaction => {
                const amount = parseFloat(transaction.amount);
                if (transaction?.transaction_type === "adjustment") {
                    currentTotal = amount; // Asumir el primer 'adjustment' como saldo de apertura
                } else {
                    currentTotal += amount; // Sumar todas las transacciones para el saldo actual
                }
            });

            setOpeningBalance(opening);
            setCurrentBalance(current);
            setCurrentBalanceTotal(currentTotal);
        }
    }

    const withdrawBalance = async (amount) => {
        const response = await fetch(`https://api.flashpass.com.ar/menu-pos-sessions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({
                session_id: session.session_id,
                pos_id: session.pos_id,
                transaction_type: "withdraw",
                amount: amount,
                type: "withdraw",
                event_id: session.event_id,
                menu_id: session.menu_id
            }),
        });

        const result = await response?.json();

        if (result.status === "success") {
            const transactionId = result.transaction_id;
            fetchPendingPrinting(session.pos_id, "withdrawal", transactionId)
            Swal.fire({
                title: 'Retiro exitoso',
                text: `Se retiraron ${money_format(amount)} de la caja`,
                icon: 'success',
                confirmButtonText: 'Aceptar'
            });
            fetchData();
        } else {
            Swal.fire({
                title: 'Error',
                text: `Ocurrió un error al retirar dinero de la caja`,
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
        }
    }

    const closeOutBalance = async (amount, observations) => {
        const response = await fetch(`https://api.flashpass.com.ar/menu-pos-sessions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({
                session_id: session.session_id,
                pos_id: session.pos_id,
                amount: amount,
                type: "close",
                event_id: session.event_id,
                menu_id: session.menu_id,
                observations: observations
            }),
        });

        const result = await response?.json();

        fetchPendingPrinting(session.pos_id, "close");

        if (result.status === "success") {
            fetchData();
            Swal.fire({
                title: 'Cierre de caja exitoso',
                text: `Se cerró la caja con un saldo de $${amount}`,
                icon: 'success',
                confirmButtonText: 'Aceptar'
            }).then(() => {
                localStorage.removeItem('session');
                navigate('/home');
            });
        } else {
            Swal.fire({
                title: 'Error',
                text: `Ocurrió un error al cerrar la caja`,
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
        }
    }

    const duringShiftOptions = [
        { label: "Ingresar dinero", onClick: () => { /* Lógica para ingresar dinero */ } },
        { label: "Retirar dinero", onClick: () => requestAmount(withdrawBalance) },
    ];

    const endShiftOptions = [
        { label: "Cerrar caja", onClick: () => requestCloseOutBalance(closeOutBalance) },
    ];

    useEffect(() => {
        if (session.status === "closed") {
            localStorage.removeItem('session');
            navigate('/home');
        }
    }, [session]);

    useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 100);
        return () => clearTimeout(requests);
    }, []);

    let balance = 0; // Inicializar el saldo fuera del map para calcularlo acumulativamente



    return (
        <>
            <div className="row" style={{ height: "100vh", width: "100vw", overflow: "hidden" }}>
                <div className="col-md-10 cash-register-details">
                    <div className="row cash-register-header">
                        <div className="col">
                            <button
                                style={{backgroundColor: "transparent", border: "none", margin: 0, padding: 0}}
                                onClick={() => {
                                    navigate(-1);
                                }}>
                                    <FaLongArrowAltLeft className="me-2" size={25}/>
                            </button>
                        </div>
                        <div className="col">
                            <h2>Gestión de Caja</h2>
                        </div>
                        <div className="col-auto">
                            <p className="mb-0">Efectivo apertura: {money_format(openingBalance)}</p>
                            <p className="mb-0">Efectivo actual: {money_format(currentBalance)}</p>
                            <p className="mb-0">Venta total: {money_format(currentBalanceTotal)}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 pe-0">
                            <TableContainer component={Paper}
                                            style={{maxHeight: 'calc(100vh - 60px)', overflowY: 'auto'}}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{fontWeight: 'bold'}}>Fecha</TableCell>
                                            <TableCell sx={{fontWeight: 'bold'}}>Concepto</TableCell>
                                            <TableCell sx={{fontWeight: 'bold'}}>Monto</TableCell>
                                            <TableCell sx={{fontWeight: 'bold'}}>Saldo</TableCell>
                                            <TableCell sx={{fontWeight: 'bold'}}>Método de pago</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Usuario</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {transactions.map((transaction, index) => {
                                            const amount = parseFloat(transaction.amount);

                                            if (transaction.transaction_type === "adjustment") {
                                                balance = amount;
                                            } else {
                                                balance += amount;
                                            }

                                            return (
                                                <TableRow key={index}>
                                                    <TableCell>{transaction.created_at}</TableCell>
                                                    <TableCell>{transaction.transaction_type}</TableCell>
                                                    <TableCell>{money_format(transaction.amount)}</TableCell>
                                                    <TableCell>{money_format(balance)}</TableCell>
                                                    <TableCell>{transaction.payment_method}</TableCell>
                                                    <TableCell>{transaction.created_by}</TableCell> {/* Adjust according to your data */}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
                <div className="col-md-2 cash-register-options mt-md-5">
                    <p className="mb-0">Durante el turno</p>
                    {duringShiftOptions.map((option, index) => (
                        <button key={index} onClick={option.onClick} className="btn btn-primary my-2"
                                style={{width: '100%'}}>
                            {option.label}
                        </button>
                    ))}
                    <p className="mb-0 mt-4">Al final del turno</p>
                    {endShiftOptions.map((option, index) => (
                        <button key={index} onClick={option.onClick} className="btn btn-primary my-2"
                                style={{width: '100%'}}>
                            {option.label}
                        </button>
                    ))}
                </div>
            </div>
        </>
    );
};

export default CashRegister;