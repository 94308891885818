export function number_format (number, decimals, dec_point = ',', thousands_sep = '.') {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = (!isFinite(+decimals) || Number.isInteger(n)) ? 0 : Math.abs(decimals), // Adjust precision based on whether number is an integer
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return "" + Math.round(n * k) / k;
        };

    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || "").length < prec) {
        s[1] = s[1] || "";
        s[1] += new Array(prec - s[1].length + 1).join("0");
    }
    return s.join(dec);
}

export function money_format(number, decimals, dec_point = ',', thousands_sep = '.') {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        absN = Math.abs(n), // get absolute value of n
        prec = (!isFinite(+decimals) || Number.isInteger(absN)) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(absN, prec) : '' + Math.round(absN)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return (n < 0 ? "-$ " : "$ ") + s.join(dec); // add $ sign, and - before $ if negative
}

export const splitNumber = (number) => {
    let [integerPart, decimalPart] = parseFloat(number).toFixed(2).split('.');

    // Add thousands separator
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return {
        integer: integerPart,
        decimal: decimalPart
    };
}

export const formatAmountDisplay = (amountInCents) => {
    // Convert cents to a string representing dollars with two decimal places
    let amountAsString = (amountInCents / 100).toFixed(2);

    // Split the string into whole and decimal parts
    let [whole, decimal] = amountAsString.split('.');

    // Reverse the whole part to insert the thousand separator
    let reversedWhole = whole.split('').reverse().join('');

    // Insert a dot every three characters (thousand separator)
    let withThousandSeparator = reversedWhole.match(/.{1,3}/g).join('.');

    // Reverse back to normal order
    let formattedWhole = withThousandSeparator.split('').reverse().join('');

    // Replace the decimal point with a comma and return the formatted amount
    return `$${formattedWhole},${decimal}`;
};

const symbols = [
    { value: 1, symbol: '', digits: 0 },
    { value: 1e3, symbol: 'k', digits: 0 },
    { value: 1e6, symbol: 'M', digits: 2 },
    { value: 1e9, symbol: 'G', digits: 0 },
    { value: 1e12, symbol: 'T', digits: 0 },
    { value: 1e15, symbol: 'P', digits: 0 },
    { value: 1e18, symbol: 'E', digits: 0 }
];

export function reducedNumberFormatter(num) {
    const numToCheck = Math.abs(num);
    for (let i = symbols.length - 1; i >= 0; i--) {
        if (numToCheck >= symbols[i].value) {
            let newNumber;
            // Check if the number is a whole number or has decimals
            if (Number.isInteger(num / symbols[i].value)) {
                // If it's a whole number, don't add decimals
                newNumber = (num / symbols[i].value).toString();
            } else {
                // If it has decimals, limit to 1 decimal place
                // But only if the resulting number is less than 1e5 or if it's not defined to have specific digits
                if (numToCheck < 1e5 || symbols[i].digits === 0) {
                    newNumber = (num / symbols[i].value).toFixed(1);
                } else {
                    // For larger numbers or where specific formatting is required, use the symbol's digits
                    newNumber = (num / symbols[i].value).toFixed(symbols[i].digits);
                }
            }
            return `${newNumber}${symbols[i].symbol}`;
        }
    }
    return '0';
}
