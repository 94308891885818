import React from 'react';
import {FaMoneyCheckDollar, FaRegCreditCard} from "react-icons/fa6";
import {BsCashStack} from "react-icons/bs";
import {IoQrCodeOutline} from "react-icons/io5";
import {formatAmountDisplay} from "./functions/numberFormat";
import {useNavigate} from "react-router-dom";
import {GrUserPolice} from "react-icons/gr";
import {BiSolidBank} from "react-icons/bi";

const PaymentMethods = (props) => {

    const navigate = useNavigate();

    return (
        <>
            <div className="row scrollable-container">
                <div className="col-12">
                    <div className="row" style={{marginTop: "23px"}}>
                        <div className="col-6" style={{paddingRight: "2px"}}>
                            <div
                                className={`payment-method-container-terminal ${props.amountDueInCents <= 0 ? "disabled" : null} ${props.paymentMethod === "cash" ? "selected" : ""}`}
                                onClick={() => props.amountDueInCents <= 0 ? null : props.setPaymentMethod("cash")}>
                                <BsCashStack
                                    className="payment-method-icon-terminal"/>
                                <span className="m-0 ms-2">
                                    Efectivo
                                </span>
                            </div>
                        </div>
                        <div className="col-6" style={{paddingLeft: "2px"}}>
                            <div
                                className={`payment-method-container-terminal ${props.amountDueInCents <= 0 ? "disabled" : null} ${props.paymentMethod === "bank_transfer" ? "selected" : ""}`}
                                onClick={() => props.amountDueInCents <= 0 ? null : props.setPaymentMethod("bank_transfer")}>
                                <BiSolidBank
                                    className="payment-method-icon-terminal"/>
                                <span className="m-0 ms-2">
                                    Transferencia
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col-6" style={{paddingRight: "2px"}}>
                            <div
                                className={`payment-method-container-terminal ${props.amountDueInCents <= 0 ? "disabled" : null} ${props.paymentMethod === "checking" ? "selected" : ""}`}
                                onClick={() => props.amountDueInCents <= 0 ? null : props.setPaymentMethod("checking")}>
                                <FaMoneyCheckDollar
                                    className="payment-method-icon-terminal"/>
                                <span className="m-0 ms-2">
                                    Cta Corriente
                                </span>
                            </div>
                        </div>
                        <div className="col-6" style={{paddingLeft: "2px"}}>
                            <div
                                className={`payment-method-container-terminal ${props.amountDueInCents <= 0 ? "disabled" : null} ${props.paymentMethod === "security_staff" ? "selected" : ""}`}
                                onClick={() => props.amountDueInCents <= 0 ? null : props.setPaymentMethod("security_staff")}>
                                <GrUserPolice
                                    className="payment-method-icon-terminal"/>
                                <span className="m-0 ms-2">
                                    Seguridad
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col-6" style={{paddingRight: "2px"}}>
                            <div
                                className={`payment-method-container-terminal ${props.amountDueInCents <= 0 ? "disabled" : null} ${props.paymentMethod === "card" ? "selected" : ""}`}
                                onClick={() => props.amountDueInCents <= 0 ? null : props.setPaymentMethod("card")}>
                                <FaRegCreditCard
                                    className="payment-method-icon-terminal"/>
                                <span className="m-0 ms-2">
                                    Tarjeta
                                </span>
                            </div>
                        </div>
                        <div className="col-6" style={{paddingLeft: "2px"}}>
                            <div
                                className={`payment-method-container-terminal ${props.amountDueInCents <= 0 ? "disabled" : null} ${props.paymentMethod === "qr" ? "selected" : ""}`}
                                onClick={() => props.amountDueInCents <= 0 ? null : props.setPaymentMethod("qr")}>
                                <IoQrCodeOutline
                                    className="payment-method-icon-terminal"/>
                                <span className="m-0 ms-2">
                                    QR
                                </span>
                            </div>
                        </div>
                    </div>
                    {props.amountDueInCents > 0 ? (
                        <div className="row mt-4">
                            <div className="col-12">
                                <button
                                    className={"payment-button-terminal"}
                                    onClick={props.submitPayment}
                                    disabled={props.amountDueInCents <= 0 || props.isPaymentSubmiting}
                                >
                                    {isNaN(props.paymentAmount) ? "Pagar" : "Pagar " + formatAmountDisplay(props.paymentAmount * 100)}
                                </button>
                            </div>
                        </div>
                    ) : null}
                    {props.amountDueInCents <= 0 ? (
                        <div className="row mt-4">
                            <div className="col-12">
                                <button
                                    className={"payment-button-new-order"}
                                    onClick={() => navigate("/orders/new")}
                                >
                                    Nueva orden
                                </button>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default PaymentMethods;