import React, { useEffect, useState } from 'react';
import setupDatabase from './database';
import {Provider} from "react-redux";
import {BrowserRouter, Route, Routes, useLocation, useNavigate, useParams} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material";
import store from "./redux/configureStore";
import Home from "./components/Home";
import OrderInterface from "./components/OrderInterface";
import OrdersListInterface from "./components/OrdersListInterface";
import Login from "./components/Login";
import {login} from "./redux/userSlice";
import CashRegister from "./components/CashRegister";
import CashRegistersInterface from "./components/CashRegistersInterface";
import PendingPrinting from "./components/PendingPrinting";
import TablesPrintingService from "./components/TablesPrintingService";
import KitchenDisplayMonitor from "./components/KitchenDisplayMonitor";

function RedirectBasedOnAuthStatus() {
    const navigate = useNavigate();
    const location = useLocation();

    const checkTokenValid = async () => {
        const request = await fetch(`https://api.flashpass.com.ar/validate-jwt`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        });

        if (request.status === 200) {
            return true;
        } else {
            window.localStorage.removeItem('token');
            if (location.pathname !== "/pending-printing" || location.pathname !== "/tables-printing-service") {
                window.location.href = '/';
            }
        }
    }

    useEffect(() => {
        const userLogged = !!localStorage.getItem("token");
        const session = JSON.parse(localStorage.getItem("session"));

        if (!userLogged) {
            navigate("/login");
        }
        else if (userLogged && !session?.session_id && location.pathname !== "/cash-register-interface" && location.pathname !== "/pending-printing" && location.pathname !== "/tables-printing-service") {
            checkTokenValid();
            navigate("/home");
        } else {
            checkTokenValid();
        }
    }, [navigate]);

    return null; // This component does not render anything
}

function App() {
    const [db, setDb] = useState(null);
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    const getToken = window.localStorage.getItem('token');

    useEffect(() => {
        if (getToken) {
            store.dispatch(login({
                token: getToken,
            }));
        }
    }, [getToken]);

    const checkTokenValid = async () => {
        const request = await fetch(`https://api.flashpass.com.ar/validate-jwt`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + getToken,
            }
        });

        if (request.status === 200) {
            return true;
        } else {
            window.localStorage.removeItem('token');
            window.location.href = '/';
        }
    }

    useEffect(() => {
        if (getToken) {
            checkTokenValid();
        }
    }, [getToken]);

    useEffect(() => {
        async function initializeDB() {
            const database = await setupDatabase();
            setDb(database);
        }
        initializeDB();
    }, []);

    useEffect(() => {
        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);

        return () => {
            window.removeEventListener('online', updateOnlineStatus);
            window.removeEventListener('offline', updateOnlineStatus);
        };
    }, []);

    const updateOnlineStatus = () => {
        setIsOnline(navigator.onLine);
    };

    useEffect(() => {
        if (isOnline) {
            //syncDataWithAPI();
        }
    }, [isOnline, db]);

    const lightTheme = createTheme({
        palette: {
            mode: 'light',
        }
    });

    useEffect(() => {
        document.addEventListener('wheel', function(event) {
            if (document.activeElement.type === 'number') {
                event.preventDefault();
            }
        }, { passive: false });
    }, []);

    const OrderId = () => {
        let { id } = useParams();
        return <OrderInterface id={id} />
    }

    return (
        <Provider store={store}>
            <div className="App">
                <ThemeProvider theme={lightTheme}>
                    <BrowserRouter>
                        <RedirectBasedOnAuthStatus />
                        <Routes>
                            <Route path="/" element={<Login />} />
                            <Route path="/home" element={<Home />} />
                            <Route path="/cash-register" element={<CashRegister />} />
                            <Route path="/cash-register-interface" element={<CashRegistersInterface />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/orders" element={<OrdersListInterface />} />
                            <Route path="/orders/:id" element={<OrderId />} />
                            <Route path="/orders/new" element={<OrderInterface />} />
                            <Route path="/tables-printing-service" element={<KitchenDisplayMonitor />} />
                            <Route path="/pending-printing" element={<PendingPrinting />} />
                        </Routes>
                    </BrowserRouter>
                </ThemeProvider>
            </div>
        </Provider>
    );
}

export default App;
