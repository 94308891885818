import React, {useEffect, useRef, useState} from 'react';
import {CircularProgress} from "@mui/material";
import PanelBarraOrderComponent from "./PanelBarraOrderComponent";
import {useNavigate} from "react-router-dom";
import {triggerPrinting} from "./functions/printOrder";
import '../styles/KDM.css';
import {FaCog, FaHome, FaUser} from "react-icons/fa";
import { FaShop } from "react-icons/fa6";


const KitchenDisplayMonitor = () => {
    const navigate = useNavigate();

    const [orders, setOrders] = React.useState([]);
    const [isLoaded, setIsLoaded] = useState(true);
    const token = localStorage.getItem('token');

    // ------ IMPORTADO DE TABLE PRINTING SERVICE ----------
    const [pendingPrinting, setPendingPrinting] = useState([]);
    const [printedUrls, setPrintedUrls] = useState([]);
    const [historicalUrls, setHistoricalUrls] = useState([]);
    const historicalUrlsRef = useRef([]);
    const [pos, setPos] = useState([]);
    const [selectedTables, setSelectedTables] = useState([]);
    const isPrintingRef = useRef(false);

    const fetchTables = async () => {
        const request = await fetch('https://api.flashpass.com.ar/venue-tables', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        });

        if (!request.ok) {
            throw new Error(`Error: ${request.status}`);
        }

        const result = await request.json();

        if (result?.status === 'success') {
            setPos(result?.data);
        }
    };
    // ------ IMPORTADO DE TABLE PRINTING SERVICE ----------

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedTables([...selectedTables, value]);
        } else {
            setSelectedTables(selectedTables.filter((table) => table !== value));
        }
    };

    const fetchData = async () => {
        try {
            const posIdsParam = selectedTables.join(','); // Join all posIds with a comma
            const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-table-orders-kds?table=${posIdsParam}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'credentials': 'same-origin',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!request.ok) {
                throw new Error(`Error: ${request.status}`);
            }

            const result = await request.json();

            if (result?.status === 'success') {
                setOrders(result.data.filter((order) => order?.order_status === 'processing'));
                setIsLoaded(true);
            }
        } catch (error) {
            console.error('Failed to fetch orders:', error);
        }
    }

    const deliverOrder = async (order_hash) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/menu-orders?order_id=${order_hash}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + token,
            },
        });

        const result = await response.json();
        fetchData();

        return result;
    }

    // ------ IMPORTADO DE TABLE PRINTING SERVICE ----------

    const fetchPendingPrinting = async (posIds) => {
        try {
            const posIdsParam = posIds.join(','); // Join all posIds with a comma
            const request = await fetch(`https://api.flashpass.com.ar/table-printing-service?table=${posIdsParam}&version=v2`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'credentials': 'same-origin',
                },
            });

            if (!request.ok) {
                throw new Error(`Error: ${request.status}`);
            }

            const result = await request.json();

            // Filter out URLs that are already printed or in the historicalUrlsRef state and remove duplicates
            const now = Date.now();
            const newTasks = result.filter((task, index, self) =>
                !printedUrls.includes(task.url) &&
                !historicalUrlsRef.current.some(urlObj => urlObj.url === task.url && (now - urlObj.timestamp) < 60000) &&
                index === self.findIndex((t) => t.url === task.url)
            );

            // Update state with new tasks
            setPendingPrinting(prevPendingPrinting => [...prevPendingPrinting, ...newTasks]);

            if (!isPrintingRef.current && newTasks.length > 0) {
                isPrintingRef.current = true;
                triggerPrintingQueue([...newTasks]);
            }
        } catch (error) {
            console.error("Failed to fetch pending printing tasks:", error);
        }
    };

    const triggerPrintingQueue = (tasks) => {
        if (tasks.length === 0) {
            isPrintingRef.current = false;
            return;
        }

        const [currentTask, ...remainingTasks] = tasks;

        console.log(currentTask, ...remainingTasks);

        // Mark URL as printed
        setPrintedUrls(prevPrintedUrls => [...prevPrintedUrls, currentTask.url]);
        setHistoricalUrls(prevHistoricalUrls => {
            const newHistoricalUrls = [...prevHistoricalUrls, { url: currentTask.url, timestamp: Date.now() }];
            historicalUrlsRef.current = newHistoricalUrls; // Update the ref
            return newHistoricalUrls;
        });

        // Trigger printing
        triggerPrinting(currentTask.order_hash, currentTask.url);

        setTimeout(() => {
            // Remove URL from pending tasks
            setPendingPrinting(prevPendingPrinting => prevPendingPrinting.filter(task => task.url !== currentTask.url));

            // Continue with the remaining tasks
            triggerPrintingQueue(remainingTasks);
        }, 4000); // 4 seconds delay between each print
    };

    useEffect(() => {
        fetchTables();
    }, []);

    useEffect(() => {
        if (selectedTables.length > 0) {
            const fetchInterval = setInterval(() => {
                fetchPendingPrinting(selectedTables);
                fetchData();
            }, 4000); // Fetch every 4 seconds

            return () => clearInterval(fetchInterval); // Cleanup interval on component unmount
        }
    }, [selectedTables]);

    useEffect(() => {
        // Clean up historical URLs older than 60 seconds
        const cleanupInterval = setInterval(() => {
            const now = Date.now();
            setHistoricalUrls(prevHistoricalUrls => {
                const newHistoricalUrls = prevHistoricalUrls.filter(urlObj => (now - urlObj.timestamp) < 60000);
                historicalUrlsRef.current = newHistoricalUrls; // Update the ref
                return newHistoricalUrls;
            });
        }, 10000); // Check every 10 seconds

        return () => clearInterval(cleanupInterval); // Cleanup interval on component unmount
    }, []);

    // ------ IMPORTADO DE TABLE PRINTING SERVICE ----------

    return (
        <>
            {!isLoaded ? (
                <div className="container" style={{minHeight: "100vh"}}>
                    <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                        <CircularProgress color="secondary" size={100} />
                    </div>
                </div>
            ) : (
                <div className="row">
                    <div className="col-3 col-lg-0">
                        <div className="sidebar_xyz123">
                            <ul className="sidebar-menu_xyz123">
                                {/* Main Item with Subitems */}
                                <li className="sidebar-menu-item_xyz123">
                                    <div className="sidebar-item-container_xyz123">
                                        <FaShop className="sidebar-icon_xyz123"/>
                                        <span className="sidebar-text_xyz123">Mesas</span>
                                    </div>
                                    <ul className="sidebar-submenu_xyz123">
                                        {pos?.map((table, index) => (
                                            <li className="sidebar-submenu-item_xyz123">
                                                <input
                                                    type="checkbox"
                                                    id={table.id}
                                                    value={table.id}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <label htmlFor={table.id} className="sidebar-submenu-label_xyz123">
                                                    {table.table_name}
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                </li>

                                {/* Other Main Items
                                <li className="sidebar-menu-item_xyz123">
                                    <div className="sidebar-item-container_xyz123">
                                        <FaUser className="sidebar-icon_xyz123"/>
                                        <span className="sidebar-text_xyz123">Profile</span>
                                    </div>
                                </li>
                                <li className="sidebar-menu-item_xyz123">
                                    <div className="sidebar-item-container_xyz123">
                                        <FaCog className="sidebar-icon_xyz123"/>
                                        <span className="sidebar-text_xyz123">Settings</span>
                                    </div>
                                </li>
                                */}
                            </ul>
                        </div>
                    </div>
                    <div className="col-9 col-lg-12">
                        <div className="container" style={{minHeight: "100vh"}}>
                            <div className="row mt-2 justify-content-between align-items-center">
                                <div className="col">
                                    <h3>Pedidos</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    {orders?.filter((_, index) => index % 3 === 0).map((order, index) => (
                                        <PanelBarraOrderComponent key={index} order={order}
                                                                  deliverOrder={deliverOrder} printOrder={() => triggerPrinting(order?.order_hash, order?.order_url)}/>
                                    ))}
                                </div>
                                <div className="col-md-4">
                                    {orders?.filter((_, index) => index % 3 === 1).map((order, index) => (
                                        <PanelBarraOrderComponent key={index} order={order}
                                                                  deliverOrder={deliverOrder} printOrder={() => triggerPrinting(order?.order_hash, order?.order_url)}/>
                                    ))}
                                </div>
                                <div className="col-md-4">
                                    {orders?.filter((_, index) => index % 3 === 2).map((order, index) => (
                                        <PanelBarraOrderComponent key={index} order={order}
                                                                  deliverOrder={deliverOrder} printOrder={() => triggerPrinting(order?.order_hash, order?.order_url)}/>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default KitchenDisplayMonitor;