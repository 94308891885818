import Swal from "sweetalert2";

export const printOrder = async (orderId, setIsLoadingCallback) => {
    // Check if the screen is 'large' or bigger (typically this means the screen width is 992px or more)
    if (window.matchMedia('(min-width: 992px)').matches) {
        // Construct the URL with the given orderId
        const url = `https://flashpass.com.ar/pos-order-printing?order=${orderId}&type=only-reciept&token=${localStorage.getItem('token')}`;

        // Open the new tab
        const newWindow = window.open(url, '_blank');

        // Check if the new window was successfully opened
        if (newWindow) {
            // Wait for the new window to load its content
            newWindow.onload = function() {
                // Execute print command in the new window
                newWindow.print();

                // Close the new window
                newWindow.close();
            };
        } else {
            // Handle the case where the pop-up window is blocked
            alert("Pop-up bloqueado. Por favor, habilite los pop-ups para poder imprimir el ticket.");
        }
    } else {
        // Optionally handle the case where the screen is not large enough
        console.log("Printing is disabled on small devices. Please switch to a larger screen to print the order.");
    }

    if (setIsLoadingCallback) {
        setIsLoadingCallback(false);
    }
};

export const printOrderInSession = async (orderID) => {

    const request = await fetch(`https://api.flashpass.com.ar/pos-printing-service`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token'),
            "credentials": "same-origin",
        },
        body: JSON.stringify({
            "order_id": orderID,
        }),
    });

    const response = await request?.json();

    if (response?.status === "success") {
        Swal.fire({
            title: '¡Éxito!',
            text: 'El pedido se ha impreso con éxito.',
            icon: 'success',
            confirmButtonText: 'Ok'
        });
    } else {
        Swal.fire({
            title: '¡Error!',
            text: 'No se pudo imprimir el pedido.',
            icon: 'error',
            confirmButtonText: 'Ok'
        });
    }
};

export const triggerPrinting = async (orderId, url, setIsLoadingCallback) => {
    // Construct the URL with the given orderId
    url = `${url}&token=${localStorage.getItem('token')}`;

    // Open the new tab
    const newWindow = window.open(url, '_blank');

    // Check if the new window was successfully opened
    if (newWindow) {
        // Wait for the new window to load its content
        newWindow.onload = function() {
            // Execute print command in the new window
            newWindow.print();

            // Close the new window
            newWindow.close();
        };
    } else {
        // Handle the case where the pop-up window is blocked
        alert("Pop-up bloqueado. Por favor, habilite los pop-ups para poder imprimir el ticket.");
    }

    if (setIsLoadingCallback) {
        setIsLoadingCallback(false);
    }
};

export const printOrderWithInvoice = async (orderId, setIsLoadingCallback, order_payment_id) => {
    // Construct the URL with the given orderId
    const url = `https://flashpass.com.ar/pos-order-printing?order=${orderId}&order_payment=${order_payment_id}&token=${localStorage.getItem('token')}`;

    // Open the new tab
    const newWindow = window.open(url, '_blank');

    // Check if the new window was successfully opened
    if (newWindow) {
        // Wait for the new window to load its content
        newWindow.onload = function() {
            // Execute print command in the new window
            newWindow.print();

            // Close the new window
            newWindow.close();
        };
    } else {
        // Handle the case where the pop-up window is blocked
        alert("Pop-up bloqueado. Por favor, habilite los pop-ups para poder imprimir el ticket.");
    }

    if (setIsLoadingCallback) {
        setIsLoadingCallback(false);
    }
};

export const printInvoice = async (order_payment_id, setIsLoadingCallback) => {
    // Construct the URL with the given orderId
    const url = `https://flashpass.com.ar/pos-invoice-printing?order_payment=${order_payment_id}`;

    // Open the new tab
    const newWindow = window.open(url, '_blank');

    // Check if the new window was successfully opened
    if (newWindow) {
        // Wait for the new window to load its content
        newWindow.onload = function() {
            // Execute print command in the new window
            newWindow.print();

            // Close the new window
            newWindow.close();
        };
    } else {
        // Handle the case where the pop-up window is blocked
        alert("Pop-up bloqueado. Por favor, habilite los pop-ups para poder imprimir el ticket.");
    }

    if (setIsLoadingCallback) {
        setIsLoadingCallback(false);
    }
};