import React from 'react';
import PaymentInput from "./PaymentInput";

const PaymentTerminal = (props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <PaymentInput
                        amountInCents={props.amountInCents}
                        setAmountInCents={props.setAmountInCents}
                        amountDueInCents={props.amountDueInCents}
                        amountTipInCents={props.amountTipInCents}
                        paymentMethod={props.paymentMethod}
                    />
                </div>
            </div>
        </>
    );
};

export default PaymentTerminal;