import React, {useEffect} from 'react';
import {Button} from "react-bootstrap";
import {useNavigate} from "react-router";
import {HiOutlineShoppingCart} from "react-icons/hi";
import {money_format} from "./functions/numberFormat";

const CarroButton = (props) => {

    const navigate = useNavigate();

    useEffect(() => {
        console.log(props.items);
    }, [props.items]);


    return (
        <div>
            {props.items.length > 0 ? (
                <div className={'d-flex justify-content-center'} style={{position: "fixed", bottom: "0", width: "100%", zIndex: 999, alignItems: "center"}}>
                    <div style={{backgroundColor: "white", width: "100%", height: "70px", display: "flex", alignItems: "center", padding: "0.8rem 1rem", boxShadow: "0px -5px 8px rgba(0, 0, 0, 0.1)"}}>
                        <Button onClick={() => props.setMobileSummaryOpen(true)} style={{width: "100%", borderRadius: ".5rem", backgroundColor: "#303139"}}>
                            <div style={{display: "flex", justifyContent: "space-between", alignContent: "center", height: "100%"}}>
                                <div>
                                    <HiOutlineShoppingCart size={30} style={{marginRight: "0.5rem"}}/>
                                </div>
                                <div style={{ height: "30px", display: "flex", alignItems: "center" }}>
                                    <p style={{ margin: 0, lineHeight: "30px" }}>Ver Carrito</p>
                                </div>
                                <div style={{ height: "30px", display: "flex", alignItems: "center", fontSize: "14px" }}>
                                    <p style={{ margin: 0, lineHeight: "30px", fontSize: "14px" }}>{
                                        money_format(
                                            props.items.reduce((total, item) => {
                                                return total + item.item.price * item.quantity
                                            }, 0), 2)
                                    }</p>
                                </div>
                            </div>
                        </Button>
                    </div>
                </div>
            ) : null
            }
        </div>
    );
};

export default CarroButton;