import React, {useEffect, useRef, useState} from 'react';
import {
    useMediaQuery,
    useTheme,
    Button, CircularProgress, DialogActions, ListItemText, ListItem, List, DialogContent, DialogTitle, Dialog, TextField
} from "@mui/material";
import PosOptionBox from "./PosOptionBox";
import {FaLongArrowAltLeft, FaSearch} from "react-icons/fa";
import {FaRegCreditCard} from "react-icons/fa6";
import CloseIcon from '@mui/icons-material/Close';
import {money_format, number_format} from "./functions/numberFormat";
import {BsCashStack} from "react-icons/bs";
import {IoQrCodeOutline} from "react-icons/io5";
import SuccessIcon from "./SuccessIcon";
import OrderSummary from "./OrderSummary";
import {useLocation, useNavigate} from "react-router-dom";
import OrderItemsSelector from "./OrderItemsSelector";
import PaymentTerminal from "./PaymentTerminal";
import PaymentMethods from "./PaymentMethods";
import {printInvoice, printOrder, printOrderWithInvoice, printOrderInSession} from "./functions/printOrder";
import CarroButton from "./CarroButton";

const OrderInterface = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const session = JSON.parse(localStorage.getItem('session'));

    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [items, setItems] = useState([]);
    const [itemsAux, setItemsAux] = useState([]);
    const [variantsAux, setVariantsAux] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedVariants, setSelectedVariants] = useState([]);
    const [posQuantity, setPosQuantity] = useState(1);
    const [paymentMethod, setPaymentMethod] = useState('cash');
    const [displayPaymentMethod, setDisplayPaymentMethod] = useState(false);
    const [waitingForPayment, setWaitingForPayment] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [paymentError, setPaymentError] = useState(false);
    const [paymentErrorMessage, setPaymentErrorMessage] = useState('');
    const [orderID, setOrderID] = useState(props.id);
    const [orderItems, setOrderItems] = useState([]);
    const [waitingForPrinting, setWaitingForPrinting] = useState(false);
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [mustUpdateOrder, setMustUpdateOrder] = useState(false);
    const [paymentAmountError, setPaymentAmountError] = useState(false);
    const [paymentAmountErrorMessage, setPaymentAmountErrorMessage] = useState(false);
    const [summarySelectedItem, setSummarySelectedItem] = useState(null);
    const [summarySelectedPayments, setSummarySelectedPayments] = useState(null);
    const [paymentHash, setPaymentHash] = useState(null);
    const [pendingPaymentIds, setPendingPaymentIds] = useState([]);
    const [client, setClient] = useState(null);
    const [clientsList, setClientsList] = useState([]);
    const [clientListOpen, setClientListOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [change, setChange] = useState(null);
    const [isPaymentSubmiting, setIsPaymentSubmiting] = useState(false);
    const [mobileSummaryOpen, setMobileSummaryOpen] = useState(false);

    const [values, setValues] = useState({
        items: [],
    });

    const shouldContinueCheckingRef = useRef(waitingForPayment);


    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };


    const handleUnselectCategory = (id) => {
        setSelectedCategories(selectedCategories.filter(categoryId => categoryId !== id));
    }

    const handleSelectCategory = (id) => {
        setSelectedCategories( [id])
    }

    const fetchData = async () => {
        const request = await fetch(`https://api.flashpass.com.ar/menu?session_id=${session?.session_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "credentials": "same-origin",
            }
        });

        const response = await request.json();

        if (response.status === 'success') {
            const allProducts = response.menu.categories.flatMap(category => {
                // For each category, map through its products
                return category.products.map(product => {
                    // Return what you want for each product
                    return {
                        productId: product.id,
                        title: product.title,
                        description: product.description,
                        price: product.price,
                        image: product.image,
                        categoryId: product.category_id,
                        categoryName: category.title,
                        variants: product.variant_categories,
                    };
                });
            });

            setItems(allProducts);
            setItemsAux([]);
        }
    }

    const fetchOrder = async (id) => {
        const request = await fetch(`https://api.flashpass.com.ar/menu-orders?order_id=${id}&action=view`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "credentials": "same-origin",
            },
        });

        const response = await request?.json();

        const products = response?.products;

        if (products) {
            const newValues = {
                ...response,
                items: products.map(product => {
                    return {
                        item: {
                            productId: product.id,
                            title: product.title,
                            description: product.description,
                            price: product.price,
                            categoryId: product.category_id,
                            categoryName: product.category_name,
                            variants: product.variants.map(variant => {
                                return {
                                    id: variant.variant_id,
                                    title: variant.name,
                                    price: variant.price,
                                    category_id: variant.category_id,
                                    categoryName: variant.category_name,
                                }
                            }),
                        },
                        variants: product.variants.map(variant => {
                            return {
                                id: variant.variant_id,
                                title: variant.name,
                                price: variant.price,
                                category_id: variant.category_id,
                                categoryName: variant.category_name,
                                description: variant.description,
                                product_id: variant.product_id
                            }
                        }),
                        quantity: product.quantity,
                    }
                }),
            };

            setValues(newValues);
            if (paymentAmount === 0) {
                setPaymentAmount(response?.remaining_amount);
            }
            setOrderItems(newValues.items);
        }
        setIsLoading(false);
    }

    const fetchUser = async (id) => {
        const request = await fetch(`https://api.flashpass.com.ar/menu-users?session_id=${session?.session_id}&user_id=${id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "credentials": "same-origin",
            },
        });

        const response = await request?.json();

        if (response?.status === 'success') {
            setClientsList(response?.users);
        }

    }

    const handleSelectVariant = (variant, category) => {
        if (!variant || !variant.category_id) {
            console.error("Invalid variant provided");
            return;
        }

        variant.categoryName = category.title;

        setSelectedVariants(prevSelectedVariants => {
            const existingIndex = prevSelectedVariants.findIndex(v => v.category_id === variant.category_id);

            if (existingIndex >= 0) {
                // Replace existing variant in the same category
                const newVariants = [...prevSelectedVariants];
                newVariants[existingIndex] = variant;
                return newVariants;
            } else {
                // Add new variant as it's in a different category
                return [...prevSelectedVariants, variant];
            }
        });
    };

    const addItem = () => {
        const item = selectedItems[0];
        const variants = selectedVariants;

        const remainingAmount = values.remaining_amount;

        const newValues = {
            ...values,
            items: [
                ...values.items,
                {
                    item: item,
                    variants: variants,
                    quantity: posQuantity,
                }
            ],
            remaining_amount: remainingAmount + (item.price * posQuantity),
        };

        setValues(newValues);
        setSelectedItems([]);
        setSelectedVariants([]);
        setPosQuantity(1);
    }

    const goToPaymentMethod = () => {
        setDisplayPaymentMethod(true);
        setSummarySelectedItem(null);
        setSummarySelectedPayments(null);
        setSelectedItems([]);
        setSelectedCategories([]);
    }

    const checkPaymentAmount = () => {
        if (paymentAmount <= 0) {
            setPaymentAmountError(true);
            setPaymentAmountErrorMessage("El monto ingresado debe ser mayor a cero.")
        } else if (values.remaining_amount && paymentAmount > values.remaining_amount) {
            setPaymentAmountError(true);
            setPaymentAmountErrorMessage("El monto ingresado debe ser menor o igual al restante.")
        } else {
            setPaymentError(false);
            setPaymentErrorMessage("");
        }
    }

    const submitOrder = async () => {
        if (!props.id) {
            const request = await fetch(`https://api.flashpass.com.ar/pos-checkout`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('token'),
                    "credentials": "same-origin",
                },
                body: JSON.stringify({
                    "total_amount": paymentAmount,
                    "pos_id": session?.pos_id,
                    "session_id": session?.session_id,
                    "items": values.items.map(item => {
                        return {
                            "id": item.item.productId,
                            "title": item.item.title,
                            "description": item.item.description,
                            "quantity": item.quantity,
                            "unit_price": item.item.price,
                            "category_id": item.item.categoryId,
                            "variants": item.variants.map(variant => {
                                return {
                                    "category_id": variant.category_id,
                                    "variant_id": variant.id,
                                    "title": variant.title,
                                    "price": variant.price,
                                }
                            })
                        }
                    })
                })
            });

            const response = await request?.json();

            if (response?.status === 201 || response?.status === 204) {
                navigate(`/orders/${response.order_id}`, { state: { goToPayment: true } });
            }
        } else {
            goToPaymentMethod();
        }
    }

    const updateOrder = async () => {
        setSummarySelectedPayments(null);
        const request = await fetch(`https://api.flashpass.com.ar/pos-checkout`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "credentials": "same-origin",
            },
            body: JSON.stringify({
                "total_amount": values.items.reduce((acc, item) => { return acc + (item.item.price * item.quantity) }, 0),
                "pos_id": session?.pos_id,
                "session_id": session?.session_id,
                "order_hash": props.id,
                "items": values.items.map(item => {
                    return {
                        "id": item.item.productId,
                        "title": item.item.title,
                        "description": item.item.description,
                        "quantity": item.quantity,
                        "unit_price": item.item.price,
                        "category_id": item.item.categoryId,
                        "variants": item.variants.map(variant => {
                            return {
                                "category_id": variant.category_id,
                                "variant_id": variant.id,
                                "title": variant.title,
                                "price": variant.price,
                            }
                        })
                    }
                })
            })
        });

        const response = await request?.json();

        if (response?.status === 201 || response?.status === 204) {
            goToPaymentMethod();
            setMustUpdateOrder(false);
        }
    }

    const submitPayment = async () => {
        setIsPaymentSubmiting(true);
        setSummarySelectedPayments(null);

        const request = await fetch(`https://api.flashpass.com.ar/pos-checkout`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "credentials": "same-origin",
            },
            body: JSON.stringify({
                "total_amount": paymentAmount,
                "payment_method": paymentMethod,
                "pos_id": session?.pos_id,
                "session_id": session?.session_id,
                "order_hash": orderID,
                "items": values.items.map(item => {
                    return {
                        "id": item.item.productId,
                        "title": item.item.title,
                        "description": item.item.description,
                        "quantity": item.quantity,
                        "unit_price": item.item.price,
                        "category_id": item.item.categoryId,
                        "variants": item.variants.map(variant => {
                            return {
                                "category_id": variant.category_id,
                                "variant_id": variant.id,
                                "title": variant.title,
                                "price": variant.price,
                            }
                        })
                    }
                })
            })
        });

        const response = await request?.json();

        if (response) {
            setIsPaymentSubmiting(false);
        }

        if (response?.status === 201 || response?.status === 204) {
            setWaitingForPayment(true);
            setPaymentHash(response.payment_hash);
            if (response?.change) {
                setChange(response?.change);
            }
        }
    }

    const voidPayment = async (id, authorizationCode) => {
        const request = await fetch(`https://api.flashpass.com.ar/menu-order-payments?order_payment_id=${id}&authCode=${authorizationCode}`, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "credentials": "same-origin",
            }

        });

        const response = await request?.json();

        return response;
    }

    const cancelPaymentIntent = async () => {
        setPaymentError(false);
        setPaymentSuccess(false);
        setPaymentErrorMessage('');
        if (waitingForPayment) {
            setWaitingForPayment(false);
            setPaymentHash(null);
            setDisplayPaymentMethod(true);
        }

        if (paymentMethod === 'card') {
            await fetch(`https://api.flashpass.com.ar/mp-point-cancel-payment-intent?order=${orderID}`, {
                method: 'DELETE',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('token'),
                    "credentials": "same-origin",
                }
            });
        }
        else if (paymentMethod === 'qr') {
            await fetch(`https://api.flashpass.com.ar/mp-qr-cancel-payment-intent?order=${orderID}`, {
                method: 'DELETE',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('token'),
                    "credentials": "same-origin",
                }
            });
        }
    }

    const checkPaymentStatus = async () => {

        if (!shouldContinueCheckingRef.current) return;

        try {
            const request = await fetch(`https://api.flashpass.com.ar/menu-order-payments?order_id=${orderID}&amount=${paymentAmount}&payment_method=${paymentMethod}&posId=${session?.pos_id}&sessionId=${session?.session_id}&payment_hash=${paymentHash}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('token'),
                    "credentials": "same-origin",
                },
            });

            const status = await request?.status;

            const response = await request?.json();

            if (status === 200) {
                if (response?.status === "approved" && response?.status_detail === "accredited") {
                    fetchOrder(orderID);
                    setPaymentSuccess(true);
                    setWaitingForPayment(false);
                    shouldContinueCheckingRef.current = false; // Stop future checks
                    setPaymentHash(null);
                    setPaymentError(false);
                    if (response.order_payment_id && response.afip_authorization) {
                        setPendingPaymentIds(prevIds => [...prevIds, response.order_payment_id]);
                    }
                    if (response.remaining_amount === 0) {
                        printOrder(orderID, setIsLoading);
                    }
                }
            }

            else if (status === 400 && response?.status !== "approved") {
                setPaymentError(true);
                setPaymentErrorMessage(response.error);
                setWaitingForPayment(false);
                shouldContinueCheckingRef.current = false; // Stop future checks
                setPaymentHash(null);
                setPaymentSuccess(false);
            }

            else {
                if (shouldContinueCheckingRef.current) {
                    setTimeout(checkPaymentStatus, 5000);
                }
            }
        } catch (error) {
            console.error(error);
            shouldContinueCheckingRef.current = false; // Stop future checks
        }
    }

    const removeItem = (index) => {
        const remaining_amount = values.remaining_amount;
        const newItems = values.items.filter((item, i) => i !== index);
        setValues({
            ...values,
            items: newItems,
            remaining_amount: remaining_amount - (values.items[index].item.price * values.items[index].quantity),
        });
        setSummarySelectedItem(null);
        setSelectedItems([]);
        setSelectedCategories([]);
    }

    const handleSelectClient = (selectedClient) => {
        setClient(selectedClient); // Assuming you have a state to hold the selected client
        setSearchTerm("");
        setClientListOpen(false); // Close the dialog after selection
    };

    const updateItems = (index, updatedItem) => {
        // Calculate the old item's total for the item being updated
        const oldItemTotal = values.items[index].item.price * values.items[index].quantity;

        // Calculate the updated item's total
        const updatedItemTotal = updatedItem.item.price * updatedItem.quantity;

        // Calculate the difference to adjust the remaining_amount
        const totalDifference = updatedItemTotal - oldItemTotal;

        // Update the items array with the updated item
        const updatedItems = values.items.map((item, i) => i === index ? { ...item, ...updatedItem } : item);

        // Update the state with the new items array and adjusted remaining_amount
        setValues(prevValues => ({
            ...prevValues,
            items: updatedItems,
            remaining_amount: prevValues.remaining_amount + totalDifference, // Adjust the remaining_amount based on the totalDifference
        }));

        // Reset selections as necessary
        setSummarySelectedItem(null);
        setSelectedItems([]);
        setSelectedCategories([]);

        // Additional logic here if needed, e.g., updating total price, quantities, etc.
    };


    useEffect(() => {
        // Update the ref each time waitingForPayment changes
        shouldContinueCheckingRef.current = waitingForPayment;

        if (waitingForPayment) {
            // Initiate checking payment status
            checkPaymentStatus();
        }

        // The cleanup function will set shouldContinueCheckingRef to false,
        // ensuring the check stops if the component unmounts or waitingForPayment changes.
        return () => {
            shouldContinueCheckingRef.current = false;
        };
    }, [waitingForPayment]);


    useEffect(() => {
        if (paymentSuccess) {
            setWaitingForPayment(false);
            setPaymentHash(null);
            if (paymentMethod === 'cash') {
                setTimeout(() => {
                    setPaymentSuccess(false);
                    setChange(null);
                }, 5000);
            } else {
                setTimeout(() => {
                    setPaymentSuccess(false);
                    setChange(null);
                }, 3000);
            }
        }
    }, [paymentSuccess]);

    const endPaymentSuccess = () => {
        setPaymentSuccess(false);
        setChange(null);
    };

    const searchInvoice = async (index = 0) => {
        if (waitingForPrinting || pendingPaymentIds.length === 0) return;
        setWaitingForPrinting(true);

        if (index >= pendingPaymentIds.length) {
            if (pendingPaymentIds.length === 0) {
                setWaitingForPrinting(false);
            } else {
                setTimeout(() => {
                    searchInvoice(0);
                }, 1000);
            }
            return;
        }

        const pendingPaymentId = pendingPaymentIds[index];
        const request = await fetch(`https://api.flashpass.com.ar/facturante-menu-webhook?order=${orderID}&order_payment=${pendingPaymentId}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "credentials": "same-origin",
            }
        });

        const responseCode = await request?.status;
        if (responseCode === 200) {
            const response = await request?.json();

            if (response?.status === 'success') {
                setPendingPaymentIds(prevIds => prevIds.filter(id => id !== pendingPaymentId));
                printInvoice(pendingPaymentId, setIsLoading);
                setWaitingForPrinting(false);
                return;
            }
        }
        setTimeout(() => {
            searchInvoice(index + 1);
        }, 1000);
    };



    const printOnlyReciept = async (orderId) => {
        // Construct the URL with the given orderId
        const url = `https://flashpass.com.ar/pos-order-printing?order=${orderId}&token=${localStorage.getItem('token')}`;

        // Open the new tab
        const newWindow = window.open(url, '_blank');

        // Check if the new window was successfully opened
        if (newWindow) {
            // Wait for the new window to load its content
            newWindow.onload = function() {
                // Execute print command in the new window
                newWindow.print();

                // Close the new window
                newWindow.close();
            };
        } else {
            // Handle the case where the pop-up window is blocked
            alert("Pop-up bloqueado. Por favor, habilite los pop-ups para poder imprimir el ticket.");
        }
        setWaitingForPrinting(false);
    }

    useEffect(() => {
        if (pendingPaymentIds.length > 0) {
            searchInvoice();
        }
    }, [pendingPaymentIds]);


    useEffect(() => {
        //search in every items different category_id and get the category name and id in an object
        const categoriesAux = items.map(item => {
            return {
                id: item.categoryId,
                name: item.categoryName,
            }
        });

        //remove duplicates
        const uniqueCategories = categoriesAux.filter((category, index) => {
            return categoriesAux.findIndex(obj => obj.id === category.id) === index;
        });

        //order them alphabetically
        uniqueCategories.sort((a, b) => a.name.localeCompare(b.name)).map((item, index) => {
            return {
                id: item.id,
                name: item.name,
            }
        });

        setCategories(uniqueCategories);
    }, [items]);

    useEffect(() => {
        if (selectedCategories.length === 0) {
            setItemsAux([]);
            return;
        }

        const filteredProducts = items.filter(item => {
            return selectedCategories.includes(item.categoryId);
        });

        setItemsAux(filteredProducts);
        //setSelectedItems([]);
    }, [selectedCategories]);

    useEffect(() => {
        setVariantsAux(items.filter(item => {
            return selectedItems.length > 0 && item.productId === selectedItems[0].productId;
        }).flatMap(item => {
            return item.variants;
        }));
        if (summarySelectedItem === null) {
            setSelectedVariants([]);
            setPosQuantity(1);
        }
    }, [selectedItems]);

    useEffect(() => {
        setPaymentAmount(values.remaining_amount);
    }, [values.items]);

    useEffect(() => {
        if (values.items === orderItems) {
            setMustUpdateOrder(false);
        } else {
            if (props.id) {
                setMustUpdateOrder(true);
            }
        }
    }, [values.items, orderItems]);

    useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
            fetchUser();
            if (props.id) {
                fetchOrder(props.id);
                if (location?.state?.goToPayment && !mustUpdateOrder) {
                    goToPaymentMethod();
                }
                else if (location?.state?.printOrder && !mustUpdateOrder) {
                    //printOrder(props.id);
                }
            } else {
                setIsLoading(false);
            }
        }, 300);
        return () => clearTimeout(requests);
    }, []);

    useEffect(() => {
        if (summarySelectedItem !== null) {
            setSelectedCategories([values?.items[summarySelectedItem]?.item?.categoryId]);
            setSelectedItems([values?.items[summarySelectedItem]?.item]);
            setSelectedVariants(values?.items[summarySelectedItem]?.variants);
            setPosQuantity(values?.items[summarySelectedItem]?.quantity);
        }
    }, [summarySelectedItem]);

    const searchProduct = (event) => {
        const search = event.target.value;

        //search by product title or category name
        const filteredProducts = items.filter(item => {
            return item.title.toLowerCase().includes(search.toLowerCase()) || item.categoryName.toLowerCase().includes(search.toLowerCase());
        });

        setItemsAux(filteredProducts);
    }

    useEffect(() => {
        setSearchTerm("");
    }, [clientListOpen]);

    // Filter and sort clients before rendering in the dialog
    const filteredAndSortedClients = clientsList
        .filter(client => {
            if (!searchTerm) return true; // Return all clients if search term is empty

            const searchValue = searchTerm.toLowerCase();
            // Check if any of the specified fields contain the search term
            return client.first_name?.toLowerCase().includes(searchValue) ||
                client.last_name?.toLowerCase().includes(searchValue) ||
                client.email?.toLowerCase().includes(searchValue) ||
                client.table_number?.toString().includes(searchValue);
        })
        .sort((a, b) => {
            // Sort by table_number if available, placing undefined or null table numbers last
            const tableNumberA = a.table_number ?? Infinity;
            const tableNumberB = b.table_number ?? Infinity;
            if (tableNumberA !== tableNumberB) {
                return tableNumberA - tableNumberB;
            }

            // Explicitly handle sorting for clients without a first_name or with an empty first_name
            const firstNameA = a.first_name ?? "\uFFFF"; // Use a high Unicode value to ensure it sorts last
            const firstNameB = b.first_name ?? "\uFFFF";

            // Adjust sorting to handle empty strings by treating them similarly to null values
            if (!firstNameA && !firstNameB) return 0; // Both are empty or null, consider them equal
            if (!firstNameA) return 1; // a has no first_name, sort it last
            if (!firstNameB) return -1; // b has no first_name, sort it last

            return firstNameA.localeCompare(firstNameB);
        });

    return (
        <div className="pos-order-interface">
            <div className="pos-parent-container">
                <div className="row" style={{marginLeft: "10px"}}>
                    <div className={`col-md-4 ${((mobileSummaryOpen && !displayPaymentMethod) && summarySelectedItem === null) || waitingForPayment || paymentSuccess ? "d-block" : "d-none d-md-block"}`}>
                        <OrderSummary
                            values={values}
                            client={client}
                            setClient={setClient}
                            setClientListOpen={setClientListOpen}
                            displayPaymentMethod={displayPaymentMethod}
                            setDisplayPaymentMethod={setDisplayPaymentMethod}
                            waitingForPayment={waitingForPayment}
                            paymentSuccess={paymentSuccess}
                            endPaymentSuccess={endPaymentSuccess}
                            change={change}
                            paymentError={paymentError}
                            setPaymentMethod={setPaymentMethod}
                            paymentMethod={paymentMethod}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            orderID={orderID}
                            cancelPaymentIntent={cancelPaymentIntent}
                            submitOrder={submitOrder}
                            updateOrder={updateOrder}
                            submitPayment={submitPayment}
                            goToPaymentMethod={goToPaymentMethod}
                            paymentAmount={paymentAmount}
                            setPaymentAmount={setPaymentAmount}
                            mustUpdateOrder={mustUpdateOrder}
                            summarySelectedItem={summarySelectedItem}
                            setSummarySelectedItem={setSummarySelectedItem}
                            summarySelectedPayments={summarySelectedPayments}
                            setSummarySelectedPayments={setSummarySelectedPayments}
                            voidPayment={voidPayment}
                            fetchOrder={fetchOrder}
                            printOrder={printOrder}
                            printOrderInSession={printOrderInSession}
                            mobileSummaryOpen={mobileSummaryOpen}
                            setMobileSummaryOpen={setMobileSummaryOpen}
                            mode={"order-interface"}
                        />
                    </div>
                    {!(mobileSummaryOpen && displayPaymentMethod) || !waitingForPayment || !paymentSuccess || summarySelectedItem !== null ? (
                        <>
                            {!displayPaymentMethod ? (
                                <div
                                    className={`col-md-8 ${(!mobileSummaryOpen && !displayPaymentMethod && !waitingForPayment && !paymentSuccess) || summarySelectedItem !== null ? "d-block" : "d-none d-md-block"}`}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="d-block d-md-none mb-2">
                                                <button
                                                    style={{
                                                        backgroundColor: "transparent",
                                                        border: "none",
                                                        margin: 0,
                                                        padding: 0,
                                                        fontSize: "1.5em",
                                                        opacity: 0.8,
                                                    }}
                                                    onClick={() => {
                                                        if (waitingForPayment) {
                                                            cancelPaymentIntent();
                                                        }
                                                        else if (selectedCategories.length > 0 && selectedItems.length === 0) {
                                                            setSelectedCategories([]);
                                                        }
                                                        else if (selectedItems.length > 0 || selectedVariants.length > 0 || posQuantity > 1 || summarySelectedItem !== null) {
                                                            setSelectedItems([]);
                                                            setSelectedVariants([]);
                                                            setPosQuantity(1);
                                                            setSummarySelectedItem(null);
                                                        }
                                                        else {
                                                            navigate("/orders");
                                                        }
                                                    }}>
                                                    <FaLongArrowAltLeft className="me-2"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <OrderItemsSelector
                                        selectedItems={selectedItems}
                                        searchProduct={searchProduct}
                                        categories={categories}
                                        handleSelectCategory={handleSelectCategory}
                                        selectedCategories={selectedCategories}
                                        itemsAux={itemsAux}
                                        setSelectedItems={setSelectedItems}
                                        posQuantity={posQuantity}
                                        setPosQuantity={setPosQuantity}
                                        addItem={addItem}
                                        selectedVariants={selectedVariants}
                                        variantsAux={variantsAux}
                                        handleSelectVariant={handleSelectVariant}
                                        summarySelectedItem={summarySelectedItem}
                                        setSummarySelectedItem={setSummarySelectedItem}
                                        removeItem={removeItem}
                                        updateItem={updateItems}
                                    />
                                </div>
                            ) : (
                                <div
                                    className={`col-md-8 ${!waitingForPayment && !paymentSuccess ? "d-block" : "d-none d-md-block"}`}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="d-block d-md-none mb-2">
                                                <button
                                                    style={{
                                                        backgroundColor: "transparent",
                                                        border: "none",
                                                        margin: 0,
                                                        padding: 0,
                                                        fontSize: "1.5em",
                                                        opacity: 0.8,
                                                    }}
                                                    onClick={() => {
                                                        setMobileSummaryOpen(true);
                                                        setDisplayPaymentMethod(false);
                                                    }}>
                                                    <FaLongArrowAltLeft className="me-2"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <PaymentTerminal
                                                amountInCents={paymentAmount * 100}
                                                setAmountInCents={setPaymentAmount}
                                                amountDueInCents={values?.remaining_amount * 100}
                                                amountTipInCents={0}
                                                paymentMethod={paymentMethod}
                                            />
                                        </div>
                                        <hr className="d-md-none mt-4 mb-2"/>
                                        <div className="col-md-6 mt-md-0">
                                            <PaymentMethods
                                                setPaymentMethod={setPaymentMethod}
                                                paymentMethod={paymentMethod}
                                                values={values}
                                                paymentAmount={paymentAmount}
                                                submitPayment={submitPayment}
                                                amountDueInCents={values?.remaining_amount * 100}
                                                isPaymentSubmiting={isPaymentSubmiting}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    ) : null}
                </div>
                {!displayPaymentMethod && !mobileSummaryOpen && (
                    <div className="d-block d-md-none">
                        <CarroButton
                            items={values.items}
                            mobileSummaryOpen={mobileSummaryOpen}
                            setMobileSummaryOpen={setMobileSummaryOpen}
                        />
                    </div>
                )}
            </div>
            <Dialog
                open={clientListOpen}
                onClose={() => setClientListOpen(false)}
                fullScreen={fullScreen}
                aria-labelledby="responsive-dialog-title"
                // Apply fixed width using the sx prop
                sx={{ '& .MuiDialog-paper': { width: '80%', maxWidth: '600px' } }} // Adjust '600px' as needed
            >
                <DialogTitle id="responsive-dialog-title">
                    <TextField
                        margin="dense"
                        id="search"
                        label="Buscar cliente"
                        type="search"
                        fullWidth
                        variant="outlined"
                        onChange={handleSearchChange}
                    />
                </DialogTitle>
                <DialogContent>
                    <List>
                        {filteredAndSortedClients.map((client, index) => (
                            <ListItem button onClick={() => handleSelectClient(client)} key={index}>
                                <ListItemText primary={client.first_name ? client.first_name + " " + client.last_name : client.email} secondary={client.table_name ? client.email + " - " + client.table_name : client.first_name ? client.email : null} />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setClientListOpen(false)}>
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default OrderInterface;