import React, {useEffect, useState} from 'react';
import {formatAmountDisplay} from "./functions/numberFormat";
import '../styles/PaymentInput.css';
import {FiDelete} from "react-icons/fi";
import {Skeleton} from "@mui/material";

const PaymentInput = (props) => {

    // Define the keys you will display
    const keys = ['1', '2', '3', '$10000', '4', '5', '6', '$20000', '7', '8', '9', '$50000', 'C', '0', '00', <FiDelete />];

    const keysWithFunctionAndClass = keys.map(key => {
        // Check if key is a string to safely use .replace, otherwise handle as JSX
        const keyClass = typeof key === 'string' ? `key-button-container ${props.amountDueInCents <= 0 ? "disabled" : ""} key-class-${key.replace(/\W/g, '')}` : `key-button-container ${props.amountDueInCents <= 0 ? "disabled" : ""} key-class-icon`;
        return {
            key: key,
            onPress: () => props.amountDueInCents <= 0 ? null : pressKey(typeof key === 'string' ? key : '⨉'), // Assuming you want FiDelete to act like '⨉'
            className: keyClass
        };
    });


    const pressKey = (key) => {
        if (key.startsWith('$')) {
            // If the key starts with '$', parse the following number and convert to cents
            const amountDollars = parseInt(key.substring(1), 10); // Remove the '$' and parse the amount
            setAmountInCents(amountDollars * 100); // Convert dollars to cents and set it
        } else {
            switch (key) {
                case 'C':
                    setAmountInCents(0);
                    break;
                case '⨉':
                    setAmountInCents(Math.floor(amountInCents / 10));
                    break;
                case '00':
                    setAmountInCents(amountInCents * 100);
                    break;
                default:
                    // For numeric keys, add to the current amount
                    const numericKey = parseInt(key, 10);
                    if (!isNaN(numericKey)) {
                        setAmountInCents(amountInCents * 10 + numericKey);
                    }
                    break;
            }
        }
    }


    const [amountInCents, setAmountInCents] = useState(props.amountInCents);

    const handleChange = (event) => {
        const value = event.target.value.replace(/[^0-9]/g, '');
        setAmountInCents(parseInt(value, 10) || 0);
    };


    useEffect(() => {
        if (props.paymentMethod !== "cash" && amountInCents > props.amountDueInCents) {
            setAmountInCents(props.amountDueInCents);
        }
    }, [amountInCents, props.paymentMethod]);

    useEffect(() => {
        props.setAmountInCents(amountInCents / 100);
    }, [amountInCents]);

    useEffect(() => {
        if (isNaN(amountInCents) || !props.amountInCents || props.amountInCents !== amountInCents) {
            setAmountInCents(props.amountInCents);
        }
    }, [props.amountInCents]);

    return (
        <div className="payment-container">
            <div className="row">
                <div className="col-12">
                    <div className="payment-inputs-grid">
                        <div className="payment-input-container">
                            <p className="mb-0">Restante</p>
                            <div className="payment-top-row-container-disabled">
                                {isNaN(props.amountDueInCents) ? (
                                    <Skeleton width={"100%"} height={40}/>
                                ) : (
                                    <input
                                        readOnly
                                        className="payment-input payment-input-disabled"
                                        onClick={() => {
                                            setAmountInCents(parseInt(props.amountDueInCents, 10) || 0);
                                        }}
                                        value={formatAmountDisplay(props.amountDueInCents - amountInCents)}
                                        placeholder="$0.00"
                                    />
                                )}
                            </div>
                        </div>
                        <div className="payment-input-container">
                            <p className="mb-0">Monto</p>
                            <div className="payment-top-row-container">
                                {isNaN(props.amountInCents) ? (
                                    <Skeleton width={"100%"} height={40}/>
                                ) : (
                                    <input className="payment-input" disabled={props.amountDueInCents <= 0} type="text"
                                           value={formatAmountDisplay(amountInCents)} onChange={handleChange}
                                           placeholder="$0.00"/>
                                )}
                            </div>
                        </div>
                        <div className="payment-input-container">
                            <p className="mb-0">Propina</p>
                            <div className="payment-top-row-container-disabled">
                                {isNaN(props.amountTipInCents) ? (
                                    <Skeleton width={"100%"} height={40}/>
                                ) : (
                                    <input disabled className="payment-input" type="text"
                                           value={formatAmountDisplay(props.amountTipInCents)} placeholder="$0.00"/>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="key-buttons-grid">
                        {keysWithFunctionAndClass.map((key, index) => (
                            <div key={index} className={key.className} onClick={() => key.onPress()}>
                                <div className="key-button">
                                    {key.key}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentInput;
