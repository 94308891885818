import React, {useEffect, useState} from 'react';
import PasswordInput from "./PasswordInput";
import {useNavigate} from "react-router-dom";

const Login = () => {

    const navigate = useNavigate();

    const [password, setPassword] = useState("");
    const [venueId, setVenueId] = useState(JSON.parse(localStorage.getItem("venue")));
    const [isLoading, setIsLoading] = useState(false);

    const submitVenueId = async () => {
        setIsLoading(true);
        const request = await fetch(`https://api.flashpass.com.ar/venue?code=${password}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
            },
        });

        const response = await request.json();
        setIsLoading(false);

        if (response.status === "success") {
            setPassword("");
            if (response.data) {
                localStorage.setItem("venue", JSON.stringify(response.data));
                setVenueId(response.data);
            }
        }
    }

    const submitPasscode = async () => {
        setIsLoading(true);
        const request = await fetch(`https://api.flashpass.com.ar/menu-collaborator-auth`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
            },
            body: JSON.stringify({
                passcode: password,
                venue: venueId.id,
                login_user: true,
            }),
        });

        const response = await request.json();
        setIsLoading(false);

        if (response.status === "success") {
            setPassword("");
            localStorage.setItem("token", response.token);

            if (response.kdm === true) {
                navigate("/tables-printing-service");
            } else {
                navigate("/home");
            }
        }
    }

    return (
        <div className="container">
            <div className="row align-content-center" style={{height: "100vh"}}>
                <div className="col-md-6 p-5 align-items-center text-center">
                    <div className="password-container">
                        <div className="row">
                            <div className="col-12">
                                {venueId ? (
                                    <img className={"img-fluid"} src={venueId.logo} alt={venueId.name} />
                                ) : (
                                    <img className={"img-fluid"} src="/flashpass_logo512.png" alt="FlashPass" />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-12">
                            <PasswordInput
                                password={password}
                                setPassword={setPassword}
                                venueId={venueId}
                                placeholder={venueId ? "Ingrese su contraseña" : "Ingrese el ID de sucursal"}
                                submitFunction={!venueId ? submitVenueId : submitPasscode}
                                isLoading={isLoading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;